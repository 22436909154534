/* Dependencies */
import {
  CogIcon,
  CubeIcon,
  DeviceMobileIcon,
  UserIcon,
} from '@heroicons/react/outline';
import { FunctionComponent, useEffect, useState } from 'react';

// Models
import { PlaceholderProps } from './Placeholder.model';

/**
 * Placeholder
 * @param props - Required component props.
 */
export const Placeholder: FunctionComponent<PlaceholderProps> = ({
  title,
  description,
  icon,
}) => {
  const [IconElement, setIconElement] = useState<JSX.Element>();

  useEffect(() => {
    switch (icon) {
      case 'space': {
        return setIconElement(
          <CogIcon className="mx-auto h-8 w-8" aria-hidden={true} />
        );
      }
      case 'monitor': {
        return setIconElement(
          <DeviceMobileIcon className="mx-auto h-8 w-8" aria-hidden={true} />
        );
      }

      case 'surface': {
        return setIconElement(
          <CubeIcon className="mx-auto h-8 w-8" aria-hidden={true} />
        );
      }

      case 'user': {
        return setIconElement(
          <UserIcon className="mx-auto h-8 w-8" aria-hidden={true} />
        );
      }
    }
  }, [icon]);

  return (
    <div className="w-full flex flex-row flex-wrap justify-center items-center text-center py-8 bg-gray-50">
      <div className="w-full">
        {IconElement}
        <h3 className="text-2xl mt-2">{title}</h3>
        {description && <p className="text-base mt-2">{description}</p>}
      </div>
    </div>
  );
};
