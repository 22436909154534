/* Dependencies */
import { FunctionComponent, useEffect, useState } from 'react';
import {
  useParams,
  useNavigate,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';

// Redux
import { useAppSelector } from '../../../../../store/hooks/hooks';

// Views
import { SpaceListView } from '../SpaceListView/SpaceListView';
import { SpaceView } from '../SpaceView/SpaceView';

// Models
import { Floor } from '../../../../../store/slices/Forms/Forms.model';

/**
 * Floor View
 * @returns
 */
export const FloorView: FunctionComponent = () => {
  const [floor, setFloor] = useState<Floor>();
  const installSurvey = useAppSelector((state) => state.forms.installSurvey);
  const { floorId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const locateFloor = installSurvey.floors.find(
      (floorEntry) => floorEntry.floorId === floorId
    );

    if (!locateFloor) {
      return navigate('/forms/install-survey');
    }
    setFloor(locateFloor);
  }, [floorId, installSurvey, navigate]);

  if (!floor) {
    return (
      <div className="w-full">
        <p>loading</p>
      </div>
    );
  }

  return (
    <>
      <div className="w-full mt-4">
        <Routes>
          <Route
            path="/list"
            element={<SpaceListView floorId={floor.floorId} />}
          />
          <Route
            path="space/:spaceId/*"
            element={<SpaceView floorId={floor.floorId} />}
          />
          <Route path="*" element={<Navigate to={`list`} />} />
        </Routes>
      </div>
    </>
  );
};
