/* Dependnecies */
import { FunctionComponent } from 'react';

// Components
import { Button } from '../../Atoms/Button/Button';

// Models
import { IntroTextWithButtonProps } from './IntroTextWithButton.model';

/**
 * Intro Text With Button
 * @param props - Required component props.
 */
export const IntroTextWithButton: FunctionComponent<
  IntroTextWithButtonProps
> = ({ title, description, button }) => {
  return (
    <div className="sm:flex sm:items-center">
      <div className="sm:flex-auto">
        <h2 className="text-xl font-semibold text-gray-900">{title}</h2>
        {description && (
          <p className="mt-2 text-sm text-gray-700">{description}</p>
        )}
      </div>
      {button && (
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <Button {...button} />
        </div>
      )}
    </div>
  );
};
