/* Dependencies */
import React, { useEffect, useState } from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/outline';

// Services
import { what3wordsClient } from '../../../../services/what3wordsClient';

// Helpers
import { uniqueId } from '../../../../helpers/uniqueId/uniqueId';
import { getDescribedBy } from '../../../../helpers/getDescribedBy/getDescribedBy';

// Models
import { What3WordsInputProps } from './What3WordsInput.model';
import { Button } from '../../../Atoms/Button/Button';

// Functions
/**
 * References input event and default value to return char count.
 * @param event - The input event if available.
 * @param defaultValue - The default component value.
 */
export const characterCount = (defaultValue: string, event?: Event): number => {
  return !event
    ? defaultValue.length
    : (event.target as HTMLInputElement).value.length;
};

/**
 * What 3 Words Component
 * @param props - Required component props.
 */
export const What3WordsInput: React.FC<What3WordsInputProps> = ({
  autoFocus,
  disabled,
  error,
  label,
  max,
  maxLength,
  min,
  minLength,
  name,
  onChange,
  onBlur,
  placeholder,
  readOnly,
  required,
  showCharCount,
  supportText,
  value,
}) => {
  // Set The Initial State
  const [id] = useState(uniqueId());
  const [charCount, setCharCount] = useState(0);
  const [describedBy, setDescribedBy] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // Set the Tool tip on ID Change
  useEffect(() => {
    setDescribedBy(getDescribedBy({ error, id, name, supportText }));
  }, [id, error, name, supportText]);

  /**
   * Sets the character count
   */
  useEffect(() => {
    if (showCharCount) {
      setCharCount(characterCount(value as string));
    }
  }, [showCharCount, value]);

  /**
   * Handles What 3 Words Functionality.
   */
  const handleWhat3Words = async () => {
    if (navigator.geolocation) {
      setIsLoading(true);
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          // Get the What 3 Words data
          const w3w = await what3wordsClient.convertTo3wa({
            coordinates: {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            },
          });

          // Update formik field value
          onChange({ target: { name, value: w3w.words } });
          setIsLoading(false);
        },
        (error) => {
          setIsLoading(false);
          if (error.code === 1) {
            alert(
              'Unable to retrieve location due to device permission settings.'
            );
          } else if (error.code === 2) {
            alert(
              'Position unavailable when attempting to retrieve location, Please Try Again.'
            );
          } else {
            alert(
              'An Unexpected Error Occured Retrieving Location, Please Try Again.'
            );
          }
        }
      );
    } else {
      alert('Geolocation is not supported by this browser.');
    }
  };

  // Render the component.
  return (
    <div className="w-full">
      <label htmlFor={id} className="block text-base font-medium text-gray-700">
        {label} {required ? <span className="text-indigo-600">*</span> : null}
      </label>
      <div className="mt-1 relative rounded-md shadow-sm">
        <input
          id={id}
          type="text"
          name={name}
          onChange={onChange.bind(this)}
          onBlur={onBlur.bind(this)}
          aria-invalid={error ? true : false}
          aria-describedby={describedBy}
          max={max}
          maxLength={maxLength}
          min={min}
          minLength={minLength}
          required={required}
          placeholder={placeholder}
          readOnly={readOnly}
          disabled={disabled}
          autoFocus={autoFocus}
          value={value ? value : ''}
          inputMode="text"
          className={`${
            error &&
            'border-red-600 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500'
          } block w-full pr-10 rounded-md`}
        />
        {/* What 3 Words */}
        <div className="w-full mt-2">
          <Button
            title="What3Words"
            ariaLabel="What3Words"
            type="secondary"
            size="small"
            onClick={handleWhat3Words}
            role="button"
            isLoading={isLoading}
          />
        </div>
        {/* / What 3 Words */}
        {error && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        )}
      </div>

      {showCharCount || error || supportText ? (
        <div className="w-full flex flex-row flex-wrap items-start mt-1">
          {error || supportText ? (
            <small
              className={`${error && 'text-red-600'}`}
              id={`${id}_${name}-help`}
              data-character-count={maxLength}
            >
              {!error ? supportText : error}
            </small>
          ) : null}
          {showCharCount && maxLength ? (
            <small className={`ml-auto text-right`}>
              <span className="sr-only" aria-live="polite" aria-atomic="true">
                You have {maxLength - charCount} characters remaining.
              </span>
              {charCount}/{maxLength}
            </small>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};
