/* Dependencies */
import React from 'react';
import { Toggle } from '../Toggle/Toggle';
import { FieldSet } from '../FieldSet/FieldSet';

// Models
import { ToggleListProps } from './ToggleList.model';

/**
 * Toggle List Component
 */
export const ToggleList: React.FC<ToggleListProps> = ({
  error,
  items,
  required,
  title,
}) => {
  return (
    <FieldSet title={title} error={error} required={required}>
      {items.map((item, index) => {
        return (
          <div className="block w-full mt-2" key={index}>
            <Toggle {...item}></Toggle>
          </div>
        );
      })}
    </FieldSet>
  );
};
