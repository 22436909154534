/* Dependencies */
import * as yup from 'yup';

// Models
import { SignInRequest } from '../../../../services/modules/Auth/Auth.model';

export interface SignInFormProps {
  /**
   * Indicates the login attempt failed.
   */
  failedLogin: boolean;
  /**
   * Handle submit event.
   */
  onSubmit: (data: SignInRequest) => void;
}

export const SignInFormSchema = yup.object({
  username: yup.string().required('Field is required.'),
  password: yup.string().required('Field is required.'),
});
