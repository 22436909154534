/* Dependencies */
import { FunctionComponent } from 'react';
import { Formik } from 'formik';
import { v4 as uuid } from 'uuid';

// Helpers
import { handleFieldError } from '../../../../helpers/handleError/handleFieldError';

// Components
import { Button } from '../../../Atoms/Button/Button';
import { FormErrorMessage } from '../../../Molecules/Forms/FormErrorMessage/FormErrorMessage';
import { Input } from '../../../Molecules/Forms/Input/Input';

// Models
import {
  CreateSiteFormProps,
  CreateSiteFormSchema,
} from './CreateSiteForm.model';

/**
 * Create Site Form
 * @param props - Required component props.
 */
export const CreateSiteForm: FunctionComponent<CreateSiteFormProps> = ({
  onSubmit,
}) => {
  return (
    <Formik
      initialValues={{
        siteName: '',
        customerName: '',
      }}
      validationSchema={CreateSiteFormSchema}
      onSubmit={async (values) => {
        onSubmit({
          siteId: uuid(),
          siteName: values.siteName,
          customerName: values.customerName,
          sitePlanIds: [],
        });
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <>
          <FormErrorMessage
            propMatch={{
              siteId: 'Site Id',
              siteName: 'Site Name',
              customerName: 'Customer Name',
            }}
          />

          <form
            className="w-full flex flex-row flex-wrap items-end"
            onSubmit={handleSubmit}
          >
            {/* Site Name */}
            <div className="w-full">
              <Input
                label="Site Name"
                name="siteName"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.siteName}
                type="text"
                inputMode="text"
                error={handleFieldError(errors, touched, 'siteName')}
                required={true}
              />
            </div>
            {/* / Site Name */}

            {/* Customer Name */}
            <div className="w-full mt-2">
              <Input
                label="Customer Name"
                name="customerName"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.customerName}
                type="text"
                inputMode="text"
                error={handleFieldError(errors, touched, 'customerName')}
                required={true}
              />
            </div>
            {/* / Customer Name */}

            {/* Submit */}
            <div className="w-full flex flex-row flex-wrap justify-end mt-4">
              <Button
                title="Start Install"
                ariaLabel="Start Install"
                role="submit"
                type="primary"
                size="medium"
                onClick={handleSubmit}
              />
              {/* Submit */}
            </div>
          </form>
        </>
      )}
    </Formik>
  );
};
