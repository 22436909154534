/* Dependencies */
import axios from 'axios';

// Export shared axios client.
export const axiosClient = axios.create({
  withCredentials: false,
  headers: {
    'Content-Type': 'application/json',
  },
});
