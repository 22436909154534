/* Dependencies */
import { FunctionComponent } from 'react';
import { Formik } from 'formik';

// Helpers
import { handleFieldError } from '../../../../helpers/handleError/handleFieldError';

// Components
import { Button } from '../../../Atoms/Button/Button';
import { FormErrorMessage } from '../../../Molecules/Forms/FormErrorMessage/FormErrorMessage';
import { Input } from '../../../Molecules/Forms/Input/Input';

// Models
import {
  InitialValues,
  UpdateSpaceFormProps,
  UpdateSpaceFormSchema,
} from './UpdateSpaceForm.model';

/**
 * Update Space Form
 * @param props - Required component props.
 */
export const UpdateSpaceForm: FunctionComponent<UpdateSpaceFormProps> = ({
  floorId,
  data,
  onSubmit,
}) => {
  const intitialValues: InitialValues = {
    spaceName: data.spaceName,
  };

  return (
    <Formik
      initialValues={intitialValues}
      validationSchema={UpdateSpaceFormSchema}
      onSubmit={(values) => {
        onSubmit({
          floorId,
          spaceId: data.spaceId,
          spaceName: values.spaceName,
        });
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <>
          <FormErrorMessage
            propMatch={{
              spaceName: 'Space Name',
            }}
          />

          <form
            className="w-full flex flex-row flex-wrap items-end"
            onSubmit={handleSubmit}
          >
            {/* Space Name */}
            <div className="w-full">
              <Input
                label="Space Name"
                name="spaceName"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.spaceName}
                type="text"
                inputMode="text"
                error={handleFieldError(errors, touched, 'spaceName')}
                required={true}
              />
            </div>
            {/* / Space Name */}

            {/* Submit */}
            <div className="w-full flex flex-row flex-wrap justify-end mt-2">
              <Button
                title="Update"
                ariaLabel="Update Space"
                role="submit"
                type="primary"
                size="medium"
                onClick={handleSubmit}
              />
              {/* Submit */}
            </div>
          </form>
        </>
      )}
    </Formik>
  );
};
