/* Dependencies */
import React, { useEffect, useState } from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/outline';

// Helpers
import { uniqueId } from '../../../../helpers/uniqueId/uniqueId';
import { getDescribedBy } from '../../../../helpers/getDescribedBy/getDescribedBy';
import { characterCount } from '../Input/Input';

// Models
import { TextAreaProps } from './TextArea.model';

/**
 * Input Component
 */
export const TextArea: React.FC<TextAreaProps> = ({
  autoFocus,
  autoComplete,
  disabled,
  error,
  label,
  maxLength,
  minLength,
  name,
  onChange,
  onBlur,
  placeholder,
  readOnly,
  required,
  showCharCount,
  supportText,
  value,
}) => {
  // Set The Initial State
  const [id] = useState(uniqueId());
  const [charCount, setCharCount] = useState(0);
  const [describedBy, setDescribedBy] = useState('');

  // Set The Initial ID
  useEffect(() => {
    if (showCharCount) {
      setCharCount(characterCount(value as string));
    }
  }, [showCharCount, value]);

  // Set the Tool tip on ID Change
  useEffect(() => {
    setDescribedBy(getDescribedBy({ error, id, name, supportText }));
  }, [id, error, name, supportText]);

  /**
   * Sets the character count.
   */
  useEffect(() => {
    if (showCharCount) {
      setCharCount(characterCount(value));
    }
  }, [showCharCount, value]);

  // Render the component.
  return (
    <div className="w-full">
      <label htmlFor={id} className="block text-base font-medium text-gray-700">
        {label} {required ? <span className="text-indigo-600">*</span> : null}
      </label>
      <div className="mt-1 relative rounded-md shadow-sm">
        <textarea
          id={id}
          name={name}
          onChange={onChange.bind(this)}
          onBlur={onBlur.bind(this)}
          aria-invalid={error ? true : false}
          aria-describedby={describedBy}
          maxLength={maxLength}
          minLength={minLength}
          required={required}
          placeholder={placeholder}
          readOnly={readOnly}
          disabled={disabled}
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          value={value ? value : ''}
          inputMode="text"
          className={`${
            error &&
            'border-red-600 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500'
          } block w-full pr-10 rounded-md`}
        ></textarea>

        {error && (
          <div className="absolute right-0 top-2 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        )}
      </div>

      {showCharCount || error || supportText ? (
        <div className="w-full flex flex-row flex-wrap items-start mt-1">
          {error || supportText ? (
            <small
              className={`${error && 'text-red-600'}`}
              id={`${id}_${name}-help`}
              data-character-count={maxLength}
            >
              {!error ? supportText : error}
            </small>
          ) : null}
          {showCharCount && maxLength ? (
            <small className={`ml-auto text-right`}>
              <span className="sr-only" aria-live="polite" aria-atomic="true">
                You have {maxLength - charCount} characters remaining.
              </span>
              {charCount}/{maxLength}
            </small>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};
