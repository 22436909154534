/* Dependencies */
import React from 'react';

// Models
import { FieldSetProps } from './FieldSet.model';

/**
 * Fieldset Component
 */
export const FieldSet: React.FC<FieldSetProps> = ({
  children,
  error,
  title,
  titleSize,
  required,
}) => {
  return (
    <div
      className={`w-full ${
        error ? 'border border-red-600 border-solid p-2' : ''
      }`}
    >
      <fieldset className="block w-full">
        <legend className={`${titleSize ? titleSize : 'p'} w-full mt-0`}>
          {title} {required ? <span className="text-indigo-700">*</span> : null}
          {error ? (
            <span className="w-full text-red-600 block">{error}</span>
          ) : null}
        </legend>
        {children}
      </fieldset>
    </div>
  );
};
