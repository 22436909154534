/* Dependencies */
import { FunctionComponent } from 'react';

// Components
import { ActionList } from '../../../../components/Organisms/ActionList/ActionList';

/**
 * Form List View
 * @returns
 */
export const FormListView: FunctionComponent = () => {
  return (
    <>
      <h1 className="text-3xl">Available Forms</h1>
      <div className="w-full mt-4">
        <ActionList
          entries={[
            {
              title: 'Install Survey',
              description: 'Survey for installing a new space',
              url: '/forms/install-survey',
            },
          ]}
        />
      </div>
    </>
  );
};
