/* Dependencies */
import { AirMonitorType } from '../../components/Organisms/Forms/CreateAirMonitorForm/CreateAirMonitorForm.model';

/**
 * Retrieve the install link for air montors.
 * @param value - The value to be processed.
 * @returns
 */
export const getAirMonitorInstallLink = (
  value: AirMonitorType
): string | null => {
  switch (value) {
    case 'AirThings Hub': {
      return 'https://www.youtube.com/watch?v=rMcYw44PvUs';
    }

    case 'AirThings ViewPlus': {
      return 'https://www.youtube.com/watch?v=FzwASkEx5Z8';
    }

    case 'AirThings WavePlus': {
      return 'https://www.youtube.com/watch?v=VMdeZQiF-Fc';
    }

    default: {
      return null;
    }
  }
};
