/* Dependencies */
import { FunctionComponent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Components
import { Button } from '../../../../../components/Atoms/Button/Button';
import { Modal } from '../../../../../components/Molecules/Modal/Modal';
import { Placeholder } from '../../../../../components/Molecules/Placeholder/Placeholder';
import { ActionList } from '../../../../../components/Organisms/ActionList/ActionList';
import { CreateSpaceForm } from '../../../../../components/Organisms/Forms/CreateSpaceForm/CreateSpaceForm';
import { IntroTextWithButton } from '../../../../../components/Organisms/IntroTextWithButton/IntroTextWithButton';

// Redux
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../store/hooks/hooks';
import {
  createSpace,
  removeFloor,
  updateFloor,
} from '../../../../../store/slices/Forms/Forms.slice';

// Models
import { Floor } from '../../../../../store/slices/Forms/Forms.model';
import { SpaceListViewProps } from './SpaceListView.model';
import { UpdateFloorForm } from '../../../../../components/Organisms/Forms/UpdateFloorForm/UpdateFloorForm';

/**
 * Space List View
 * @param props - Required component props.
 * @returns
 */
export const SpaceListView: FunctionComponent<SpaceListViewProps> = ({
  floorId,
}) => {
  const [floor, setFloor] = useState<Floor>();
  const [showUpdateFloor, setShowUpdateFloor] = useState(false);
  const [showDeleteFloor, setShowDeleteFloor] = useState(false);
  const [showCreateSpaceModal, setShowCreateSpaceModal] = useState(false);
  const installSurvey = useAppSelector((state) => state.forms.installSurvey);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const locateFloor = installSurvey.floors.find(
      (floorEntry) => floorEntry.floorId === floorId
    );

    if (!locateFloor) {
      return navigate('/forms/install-survey');
    }

    setFloor(locateFloor);
  }, [floorId, installSurvey, navigate]);

  if (!floor) {
    return (
      <div className="w-full">
        <p>loading</p>
      </div>
    );
  }

  return (
    <>
      <div className="w-full mb-6">
        <Button
          title="Back To Site Overview"
          ariaLabel="Back To Site Overview"
          type="secondary"
          onClick={() => navigate('/forms/install-survey/site')}
        />
      </div>

      <div className="w-full my-4 flex flex-row">
        <Button
          title="Update Floor"
          ariaLabel="Update Floor"
          icon="edit"
          iconOnly={true}
          type="secondary"
          size="small"
          onClick={() => setShowUpdateFloor(true)}
        />
        <h2 className="text-xl ml-2">{floor.floorName}</h2>
      </div>

      <IntroTextWithButton
        title="Spaces"
        description="Add a Space to your installation. Spaces are used to group Air Monitors and Surface Tests."
        button={{
          title: 'Add Space',
          ariaLabel: 'Add Space',
          type: 'primary',
          onClick: () => {
            setShowCreateSpaceModal(true);
          },
        }}
      />

      <div className="w-full mt-4">
        {/* Spaces List */}
        {floor.spaces.length > 0 && (
          <ActionList
            entries={floor.spaces.map((space) => ({
              title: space.spaceName,
              description: `WorkFlows: ${space.workflows.length} | Air Monitors: ${space.airMonitors.length} | Surface Tests: ${space.surfaceTests.length} | People Monitors: ${space.peopleMonitors.length}`,
              url: `/forms/install-survey/floor/${floor.floorId}/space/${space.spaceId}`,
            }))}
          />
        )}
        {/* / Spaces List */}

        {/* Placeholder */}
        {!floor.spaces.length && (
          <Placeholder
            icon={'space'}
            title={'Spaces'}
            description={'Add a Space to start installation'}
          />
        )}
        {/* / Placeholder */}
      </div>

      <hr className="my-6" />

      <div className="w-full mt-6">
        <Button
          title="Delete Floor"
          ariaLabel="Delete Floor"
          icon="delete"
          type="danger"
          size="medium"
          onClick={() => setShowDeleteFloor(true)}
        />
      </div>

      {/* Create Space */}
      <Modal
        title="Create Space"
        isOpen={showCreateSpaceModal}
        onClose={() => setShowCreateSpaceModal(false)}
      >
        <div className="w-full">
          <CreateSpaceForm
            floorId={floorId}
            onSubmit={(data) => {
              dispatch(createSpace(data));
              setShowCreateSpaceModal(false);
            }}
          />
        </div>
      </Modal>
      {/* / Create Space */}

      {/* Update Floor */}
      <Modal
        title="Update Floor"
        isOpen={showUpdateFloor}
        onClose={() => setShowUpdateFloor(false)}
      >
        <UpdateFloorForm
          data={floor}
          onSubmit={(data) => {
            dispatch(updateFloor(data));
            setShowUpdateFloor(false);
          }}
        />
      </Modal>
      {/* / Update Floor */}

      {/* Delete Floor */}
      <Modal
        title="Delete Floor"
        description="Are you sure you want to delete this floor? This action cannot be undone."
        isOpen={showDeleteFloor}
        onClose={() => setShowDeleteFloor(false)}
      >
        <>
          <div className="w-full bg-indigo-700 rounded p-4 text-white">
            <p className="text-base">{floor.floorName}</p>
          </div>
          <div className="w-full mt-4">
            <Button
              title="Delete"
              ariaLabel="Delete"
              type="danger"
              onClick={() => {
                dispatch(
                  removeFloor({
                    floorId,
                  })
                );
                navigate('/forms/install-survey/site');
              }}
            />
          </div>
        </>
      </Modal>
      {/* / Delete Floor */}
    </>
  );
};
