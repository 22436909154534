/* Dependencies */
import { FunctionComponent } from 'react';
import { Formik } from 'formik';

// Helpers
import { handleFieldError } from '../../../../helpers/handleError/handleFieldError';

// Components
import { Button } from '../../../Atoms/Button/Button';
import { FormErrorMessage } from '../../../Molecules/Forms/FormErrorMessage/FormErrorMessage';
import { Input } from '../../../Molecules/Forms/Input/Input';

// Models
import {
  UpdateFloorFormProps,
  UpdateFloorFormSchema,
} from './UpdateFloorForm.model';

/**
 * Update Floor Form
 * @param props - Required component props.
 */
export const UpdateFloorForm: FunctionComponent<UpdateFloorFormProps> = ({
  data,
  onSubmit,
}) => {
  return (
    <Formik
      initialValues={{
        floorName: data.floorName,
      }}
      validationSchema={UpdateFloorFormSchema}
      onSubmit={(values) => {
        onSubmit({
          floorId: data.floorId,
          floorName: values.floorName,
        });
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <>
          <FormErrorMessage
            propMatch={{
              floorName: 'Floor Name',
            }}
          />

          <form
            className="w-full flex flex-row flex-wrap items-end"
            onSubmit={handleSubmit}
          >
            {/* Floor Name */}
            <div className="w-full">
              <Input
                label="Floor Name"
                name="floorName"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.floorName}
                type="text"
                inputMode="text"
                error={handleFieldError(errors, touched, 'floorName')}
                required={true}
              />
            </div>
            {/* / Floor Name */}

            {/* Submit */}
            <div className="w-full flex flex-row flex-wrap justify-end mt-2">
              <Button
                title="Update"
                ariaLabel="Update Floor"
                role="submit"
                type="primary"
                size="medium"
                onClick={handleSubmit}
              />
              {/* Submit */}
            </div>
          </form>
        </>
      )}
    </Formik>
  );
};
