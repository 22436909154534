/* Dependencies */
import * as yup from 'yup';

// Models
import { CreateFloorReducer } from '../../../../store/slices/Forms/Forms.model';

export interface CreateFloorFormProps {
  /**
   * Callback function to handle form submission.
   */
  onSubmit: (data: CreateFloorReducer) => void;
}

export const CreateFloorFormSchema = yup.object({
  floorName: yup.string().required('Field is required'),
});
