/* Dependencies */
import { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

// Redux
import { useAppSelector } from '../../../store/hooks/hooks';

// Helpers
import { classNames } from '../../../helpers/classNames/classNames';

// Models
import { DesktopSideBarProps } from './DesktopSideBar.model';

/**
 * DesktopSideBar component.
 * @param props - Required component props.
 */
export const DesktopSideBar: FunctionComponent<DesktopSideBarProps> = () => {
  const navigation = useAppSelector((state) => state.app.navigation);

  return (
    <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
      {/* Sidebar component, swap this element with another sidebar if you like */}
      <div className="border-r border-gray-200 pt-5 flex flex-col flex-grow bg-white overflow-y-auto">
        <div className="flex-shrink-0 px-4 flex items-center">
          <Link to="/">
            <img
              className="h-10 w-auto"
              src="/envelo-logo.png"
              alt="Envelo Logo"
            />
          </Link>
        </div>
        <div className="flex-grow mt-5 flex flex-col">
          <nav className="flex-1 px-2 pb-4 space-y-1">
            {navigation.map((item) => (
              <Link
                to={item.url}
                key={item.title}
                className={classNames(
                  item.current
                    ? 'bg-gray-100 text-gray-900'
                    : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                  'group rounded-md py-2 px-2 flex items-center text-sm font-medium'
                )}
              >
                <item.icon
                  className={classNames(
                    item.current
                      ? 'text-gray-500'
                      : 'text-gray-400 group-hover:text-gray-500',
                    'mr-3 flex-shrink-0 h-6 w-6'
                  )}
                  aria-hidden="true"
                />
                {item.title}
              </Link>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
};
