/* Dependencies */
import * as yup from 'yup';

// Models
import { CreateSpaceReducer } from '../../../../store/slices/Forms/Forms.model';

export interface WorkFlow {
  /**
   * Key
   */
  key: string;
  /**
   * Value
   */
  value: string;
}

export const workflows: WorkFlow[] = [
  {
    key: 'Internal door which can be left open',
    value: 'Internal door which can be left open',
  },
  {
    key: 'External/fire door which can be left open',
    value: 'External/fire door which can be left open',
  },
  {
    key: 'Windows which can open manually',
    value: 'Windows which can open manually',
  },
  {
    key: 'Ventilation which can be turned on or up manually',
    value: 'Ventilation which can be turned on or up manually',
  },
  {
    key: 'Ventilation which can be turned on or up automatically',
    value: 'Ventilation which can be turned on or up automatically',
  },
  { key: 'INVZBL air mitigation', value: 'INVZBL air mitigation' },
  {
    key: 'Other air mitigation (please provide details)',
    value: 'Other air mitigation (please provide details)',
  },
  {
    key: 'Option to reduce occupancy in space',
    value: 'Option to reduce occupancy in space',
  },
  {
    key: 'Option to leave greater time gaps between usage',
    value: 'Option to leave greater time gaps between usage',
  },
  {
    key: 'Other workflow (please provide details)',
    value: 'Other workflow (please provide details)',
  },
];

export interface InitialValues {
  /**
   * Name of the space.
   */
  spaceName: string;
  /**
   * Workflows of the space.
   */
  workflows: string[];
  /**
   * Workflows comment.
   */
  comment: string;
}

export interface CreateSpaceFormProps {
  /**
   * Current floor id.
   */
  floorId: string;
  /**
   * Handle submit event.
   */
  onSubmit: (data: CreateSpaceReducer) => void;
}

export const CreateSpaceFormSchema = yup.object({
  spaceName: yup.string().required('Field is required'),
  workflows: yup
    .array(yup.string())
    .min(1, 'Atleast 1 workflow is required')
    .required('Worflows is required'),
  comment: yup.string().optional(),
});
