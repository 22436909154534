/* Dependencies */
import { axiosClient } from '../../axiosClient';

// Modules
import { BaseService } from '../Base/Base.service';

// Models
import { SignInRequest, SignInResponse } from './Auth.model';

/**
 * Auth Service
 * @class
 */
export class AuthService extends BaseService {
  /**
   * Sign in the user.
   * @param payload - Request payload.
   * @returns
   */
  async signIn(payload: SignInRequest): Promise<SignInResponse> {
    return axiosClient
      .post<SignInResponse>(`${this.apiURL}/api/auth`, payload)
      .then((response) => response.data);
  }
}
