/* Dependencies */
import * as yup from 'yup';

// Models
import {
  Space,
  UpdateSpaceWorkflowsReducer,
} from '../../../../store/slices/Forms/Forms.model';

export interface UpdateSpaceWorkflowsFormProps {
  /**
   * Space data to be used.
   */
  data: Space;
  /**
   * Current floor id.
   */
  floorId: string;

  /**
   * Handle submit event.
   */
  onSubmit: (data: UpdateSpaceWorkflowsReducer) => void;
}

export interface InitialValues {
  /**
   * Workflows of the space.
   */
  workflows: string[];
  /**
   * Workflow comments.
   */
  comment: string;
}

export const UpdateSpaceWorkflowsFormSchema = yup.object({
  workflows: yup
    .array(yup.string())
    .min(1, 'Atleast 1 workflow is required')
    .required('Worflows is required'),
  comment: yup.string().optional(),
});
