/* Dependencies */
import { FunctionComponent } from 'react';
import { Formik } from 'formik';
import { v4 as uuid } from 'uuid';

// Services
import { httpClient } from '../../../../services/httpClient';

// Helpers
import { handleFieldError } from '../../../../helpers/handleError/handleFieldError';

// Components
import { Button } from '../../../Atoms/Button/Button';
import { FormErrorMessage } from '../../../Molecules/Forms/FormErrorMessage/FormErrorMessage';
import { FilePicker } from '../../../Molecules/Forms/FilePicker/FilePicker';

// Models
import {
  CompleteInstallFormProps,
  CompleteInstallFormSchema,
  InitialValues,
} from './CompleteInstallForm.model';

/**
 * Create Air Monitor Form
 * @param props - Required component props.
 */
export const CompleteInstallForm: FunctionComponent<
  CompleteInstallFormProps
> = ({ siteId, onSubmit }) => {
  const initialValues: InitialValues = {
    floorPlan: null,
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={CompleteInstallFormSchema}
      onSubmit={async (values) => {
        if (!values.floorPlan) return;

        // Upload and store floor plan ids.
        const floorPlanIds: string[] = [];
        for (let i = 0; i < values.floorPlan.length; i++) {
          // Generate Id
          const floorPlanId = uuid();
          floorPlanIds.push(floorPlanId);

          // Upload Photo
          await httpClient.forms.installFormAttachment({
            surveyId: siteId,
            imageId: floorPlanId,
            image: values.floorPlan.item(i) as File,
          });
        }

        onSubmit(floorPlanIds);
      }}
    >
      {({ values, errors, touched, handleChange, handleSubmit }) => (
        <>
          <FormErrorMessage
            propMatch={{
              floorPlan: '2D Floor Plans',
            }}
          />

          <form
            className="w-full flex flex-row flex-wrap items-end"
            onSubmit={handleSubmit}
          >
            {/* Floor Plan */}
            <div className="w-full mt-4">
              <FilePicker
                label="Floor Plans"
                name={`floorPlan`}
                onChange={handleChange}
                value={values.floorPlan as FileList}
                required={true}
                error={handleFieldError(errors, touched, `floorPlan`)}
                supportText="Ensure photos covering all Floors & Zones for the Site are uploaded"
                multiple={true}
              />
            </div>
            {/* / Floor Plan */}

            {/* Submit */}
            <div className="w-full flex flex-row flex-wrap justify-end mt-2">
              <Button
                title="Finalise Install"
                ariaLabel="Finalise Install"
                role="submit"
                type="primary"
                size="medium"
                onClick={handleSubmit}
              />
            </div>
            {/* Submit */}
          </form>
        </>
      )}
    </Formik>
  );
};
