/* Dependencies */
import * as yup from 'yup';

// Models
import {
  Space,
  UpdateSpaceReducer,
} from '../../../../store/slices/Forms/Forms.model';

export interface UpdateSpaceFormProps {
  /**
   * Space data to be used.
   */
  data: Space;
  /**
   * Current floor id.
   */
  floorId: string;

  /**
   * Handle submit event.
   */
  onSubmit: (data: UpdateSpaceReducer) => void;
}

export interface InitialValues {
  /**
   * Name of the space.
   */
  spaceName: string;
}

export const UpdateSpaceFormSchema = yup.object({
  spaceName: yup.string().required('Field is required'),
});
