/* Dependencies */
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";

// Models
import { AppState } from "./App.model";

// Mock
import { navigation, userNavigation } from "./mock";

// Define the initial state
const initialState: AppState = {
  navigation,
  userNavigation,
};

// Creaye the slice
export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    test: (state) => {
      return {
        ...state,
      };
    },
  },
});

// Export actions
export const { test } = appSlice.actions;

// Export selectors
export const selectNavigation = (state: RootState) => state.app.navigation;
export const selectUserNavigation = (state: RootState) =>
  state.app.userNavigation;

// Export reducer
export default appSlice.reducer;
