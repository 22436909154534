/* Dependencies */
import React, { useEffect, useState } from 'react';
import { EyeIcon, EyeOffIcon } from '@heroicons/react/outline';

// Helpers
import { uniqueId } from '../../../../helpers/uniqueId/uniqueId';
import { characterCount } from '../Input/Input';
import { getDescribedBy } from '../../../../helpers/getDescribedBy/getDescribedBy';

// Models
import { PasswordProps } from './Password.model';

/**
 * Password Component
 */
export const Password: React.FC<PasswordProps> = ({
  autoFocus,
  autoComplete,
  disabled,
  error,
  label,
  maxLength,
  minLength,
  name,
  onChange,
  onBlur,
  placeholder,
  readOnly,
  required,
  showCharCount,
  supportText,
  value,
}) => {
  // Set The Initial State
  const [id] = useState(uniqueId());
  const [charCount, setCharCount] = useState(0);
  const [describedBy, setDescribedBy] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  // Set The Initial ID
  useEffect(() => {
    if (showCharCount) {
      setCharCount(characterCount(value as string));
    }
  }, [showCharCount, value]);

  // Set the Tool tip on ID Change
  useEffect(() => {
    setDescribedBy(getDescribedBy({ error, id, name, supportText }));
  }, [id, error, name, supportText]);

  useEffect(() => {
    if (showCharCount) {
      setCharCount(characterCount(value));
    }
  }, [value, showCharCount]);

  // Render the component.
  return (
    <div className="w-full">
      <label htmlFor={id} className="block text-base font-medium text-gray-700">
        {label} {required ? <span className="text-indigo-600">*</span> : null}
      </label>
      <div className="mt-1 relative rounded-md shadow-sm">
        <input
          id={id}
          type={showPassword ? 'text' : 'password'}
          name={name}
          onChange={onChange.bind(this)}
          onBlur={onBlur.bind(this)}
          aria-invalid={error ? true : false}
          aria-describedby={describedBy}
          maxLength={maxLength}
          minLength={minLength}
          required={required}
          inputMode="text"
          placeholder={placeholder}
          readOnly={readOnly}
          disabled={disabled}
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          value={value ? value : ''}
          className={`${
            error &&
            'border-red-600 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500'
          } block w-full pr-10 rounded-md`}
        />

        <button
          className="form-group__password-btn absolute top-1/2 right-2 transform -translate-y-1/2 bg-transparent rounded-100 p-half border border-solid border-transparent hover:border-white focus:border-white"
          type="button"
          onClick={setShowPassword.bind(this, !showPassword)}
          title={showPassword ? 'Hide Password' : 'Show Password'}
        >
          <span className="sr-only">
            {showPassword ? 'Hide Password' : 'Show Password'}
          </span>

          <span className={`${showPassword ? 'block' : 'hidden'} w-full`}>
            <EyeIcon className="w-5 h-5 text-gray-800"></EyeIcon>
          </span>

          <span className={`${showPassword ? 'hidden' : 'block'} w-full`}>
            <EyeOffIcon className="w-5 h-5 text-gray-800"></EyeOffIcon>
          </span>
        </button>
      </div>
      {showCharCount || error || supportText ? (
        <div className="w-full flex flex-row flex-wrap items-start mt-1">
          {error || supportText ? (
            <small
              className={`${error && 'text-red-600'}`}
              id={`${id}_${name}-help`}
              data-character-count={maxLength}
            >
              {!error ? supportText : error}
            </small>
          ) : null}
          {showCharCount && maxLength ? (
            <small className={`ml-auto text-right`}>
              <span className="sr-only" aria-live="polite" aria-atomic="true">
                You have {maxLength - charCount} characters remaining.
              </span>
              {charCount}/{maxLength}
            </small>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};
