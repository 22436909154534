/* Dependencies */
import * as yup from 'yup';

// Models
export interface CompleteInstallFormProps {
  /**
   * Current site id.
   */
  siteId: string;
  /**
   * Callback function to handle form submission.
   */
  onSubmit: (sitePlanId: string[]) => void;
}

export interface InitialValues {
  /**
   * Photo of the air monitor.
   */
  floorPlan: FileList | null;
}

export const CompleteInstallFormSchema = yup.object({
  floorPlan: yup.mixed().required('Field is required'),
});
