/* Dependencies */
import { axiosClient } from '../../axiosClient';

// Modules
import { BaseService } from '../Base/Base.service';

// Models
import {
  InstallFormAttachmentRequest,
  InstallFormAttachmentResponse,
  InstallFormRequest,
  InstallFormResponse,
  ExpectedInstallFormFormat,
} from './Forms.model';

/**
 * Forms Service
 * @class
 */
export class FormsService extends BaseService {
  /**
   * Install Form.
   * @param payload - Request payload.
   * @returns
   */
  async installForm(payload: InstallFormRequest): Promise<InstallFormResponse> {
    // Map payload to desired format
    const desiredFormat: ExpectedInstallFormFormat = {
      surveyId: payload.installForm.siteId,
      siteName: payload.installForm.siteName,
      customerName: payload.installForm.customerName,
      sitePlanIds: payload.installForm.sitePlanIds,
      floors: payload.installForm.floors.map((floor) => {
        return {
          floorName: floor.floorName,
          spaces: floor.spaces.map((space) => {
            return {
              id: space.spaceId,
              spaceName: space.spaceName,
              workflows: space.workflows,
              comments: space.comment,
              airMonitors: space.airMonitors
                .filter((airMonitor) => !airMonitor.archived)
                .map((airMonitor, airMonitorIndex) => {
                  return {
                    name: airMonitor.name,
                    location: airMonitor.location,
                    locationFloorplanRef: `M${airMonitor.airMonitorIndex}`,
                    deviceType: airMonitor.deviceType,
                    serialNo: airMonitor.serialNumber,
                    deviceId: airMonitor.deviceId,
                    photoId: airMonitor.photoId,
                    comments: airMonitor.comment,
                  };
                }),
              surfaceTests: space.surfaceTests
                .filter((surfaceTest) => !surfaceTest.archived)
                .map((surfaceTest, surfaceTestIndex) => {
                  return {
                    name: surfaceTest.name,
                    location: surfaceTest.location,
                    locationFloorplanRef: `S${surfaceTest.surfaceTestIndex}`,
                    deviceType: surfaceTest.deviceType,
                    photoId: surfaceTest.photoId,
                    comments: surfaceTest.comment,
                    testResult: surfaceTest.testResult,
                  };
                }),
              peopleMonitors: space.peopleMonitors
                .filter((peopleMonitor) => !peopleMonitor.archived)
                .map((peopleMonitor, peopleMonitorIndex) => {
                  return {
                    name: peopleMonitor.name,
                    location: peopleMonitor.location,
                    locationFloorplanRef: `P${peopleMonitor.peopleMonitorIndex}`,
                    deviceType: peopleMonitor.deviceType,
                    serialNo: peopleMonitor.serialNumber,
                    deviceId: peopleMonitor.deviceId,
                    photoId: peopleMonitor.photoId,
                    comments: peopleMonitor.comment,
                  };
                }),
            };
          }),
        };
      }),
    };

    return axiosClient
      .post<InstallFormResponse>(
        `${this.apiURL}/api/InstallSurvey`,
        desiredFormat,
        {
          headers: {
            Authorization: `Bearer ${this.authToken}`,
          },
        }
      )
      .then((response) => response.data);
  }

  /**
   * Install Form Attachment.
   * @param payload - Request payload.
   * @returns
   */
  async installFormAttachment(
    payload: InstallFormAttachmentRequest
  ): Promise<InstallFormAttachmentResponse> {
    // Set the form data.
    const formData = new FormData();
    formData.append('surveyId', payload.surveyId);
    formData.append('imageId', payload.imageId);
    formData.append('image', payload.image);

    return axiosClient
      .post<InstallFormAttachmentResponse>(
        `${this.apiURL}/api/InstallSurveyAttachment`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.authToken}`,
          },
        }
      )
      .then((response) => response.data);
  }
}
