/* Dependencies */
import { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { ArrowRightIcon } from '@heroicons/react/outline';

// Models
import { ActionListProps } from './ActionList.model';

/**
 * Action List
 * @param props - Required component props.
 * @returns
 */
export const ActionList: FunctionComponent<ActionListProps> = ({ entries }) => {
  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-md">
      <ul className="divide-y divide-gray-200">
        {entries.map((entry, index) => (
          <li key={index}>
            <Link to={entry.url} className="block hover:bg-gray-50">
              <div className="px-4 py-4 sm:px-6">
                <div className="flex items-center justify-between">
                  <h2 className="text-lg font-medium text-indigo-600 truncate">
                    {entry.title}
                  </h2>
                </div>
                <div className="mt-1 sm:flex sm:justify-between">
                  <div className="sm:flex">
                    <p className="flex items-center text-sm text-gray-500">
                      {entry.description}
                    </p>
                  </div>
                  <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                    <ArrowRightIcon
                      className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                </div>
              </div>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};
