/* Dependencies */
import { FunctionComponent } from 'react';

/**
 * 2D Floor Plans
 */
export const FloorPlans: FunctionComponent = () => {
  return (
    <div className="w-full">
      <h2 className="text-2xl">Important guidance on use of 2D floorplans</h2>

      <p className="text-base my-4">
        In advance of installing on-site, please have with you a copy of 2D Floorplans covering the site where Envelo will be installed. It is essential these floorplans can be marked-up during the install. This may be done using a printed paper copy  you can mark-up in pen, or using an editable electronic version you can mark-up using a mobile device during the install.
      </p>

      <p className="text-base my-4">
        Please ensure that you mark-up all of the following on the Floorplans as you complete each step of the install:
      </p>
      <ul className="list-disc pl-5 grid grid-cols-1 gap-4">
        <li>
          Place a cross on the Floorplan where each Air Monitor and/or People Monitor has been installed. Next to each cross, label these with an “M” (for monitor) followed by a number corresponding to the number of that monitor as prescribed by this form (i.e. mark-up “M1” for “Monitor 1” as referenced in this form, “M2” for “Monitor 2”, etc)
        </li>
        <li>
          Place a cross where each Surface Test will be conducted. Next to each cross, label these with an “S” (for surface test) followed by a number corresponding to the number of that surface test as prescribed by this form (i.e. mark-up “S1” for “Surface Test 1” as referenced in this form, “S2” for “Surface Test 2”, etc)
        </li>
        <li>
          Where not already visible on the Floorplan, please label each space (i.e. room or area) with the names of those spaces (e.g. “meeting room 1”, “classroom 5”, etc)
        </li>
      </ul>
      <p className="text-base my-4">
        Please either take a photo of the final marked-up Floorplans once the install is complete, and upload these photos with this form where requested at the end of the form.
      </p>

      <p className="text-base my-4">
        Where a site has multiple Floors or Zones, you may need to upload multiple photos of marked-up Floorplans to ensure these cover all Floors and Zones.
      </p>
      <p className="text-base my-4">
        Please also ensure that a clean original pdf copy of the Floorplans are emailed to your Envelo contact as these will be used in the Envelo dashboard for the customer.
      </p>
    </div>
  );
};
