/* Dependencies */
import { FunctionComponent, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

// Views
import { FormListView } from './views/FormList/FormList.view';
import { InstallSurveyView } from './views/InstallSurvey/InstallSurvey.view';

// Models

/**
 * Forms container
 * @param props - Required component props.
 * @returns
 */
export const FormsContainer: FunctionComponent = () => {
  useEffect(() => {
    document.title = 'Envelo | Forms';
  }, []);

  return (
    <section className="w-full">
      <div className="w-full">
        <Routes>
          <Route path={`list`} element={<FormListView />} />
          <Route path={`install-survey/*`} element={<InstallSurveyView />} />
          <Route path={`*`} element={<Navigate replace to={`list`} />} />
        </Routes>
      </div>
    </section>
  );
};
