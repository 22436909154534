/* Dependencies */
import { FunctionComponent, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// Components
import { CreateSiteForm } from '../../../../../components/Organisms/Forms/CreateSiteForm/CreateSiteForm';

// Redux
import { createSite } from '../../../../../store/slices/Forms/Forms.slice';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../store/hooks/hooks';

// Mdodels
import { CreateSiteViewProps } from './CreateSiteView.model';
import { FloorPlans } from '../../../../../components/Molecules/Messaging/FloorPlans/FloorPlans';

/**
 * Create Site View
 * @returns
 */
export const CreateSiteView: FunctionComponent<CreateSiteViewProps> = ({
  nextStep,
}) => {
  const installSurvey = useAppSelector((state) => state.forms.installSurvey);
  const dispatch = useAppDispatch();
  const navigator = useNavigate();

  useEffect(() => {
    if (installSurvey.siteId) {
      navigator(`/forms/install-survey/site`);
    }
  }, [installSurvey, navigator]);

  return (
    <div className="w-full">
      <FloorPlans />
      <hr className="my-5" />
      <CreateSiteForm
        onSubmit={(data) => {
          dispatch(createSite(data));
          nextStep();
        }}
      />
    </div>
  );
};
