/* Dependencies */
import { DatabaseIcon } from '@heroicons/react/outline';

// Models
import { NavigationItem, UserNavigationItem } from './App.model';

export const navigation: NavigationItem[] = [
  { title: 'Forms', url: '/forms', icon: DatabaseIcon, current: true },
];

export const userNavigation: UserNavigationItem[] = [
  { title: 'Sign out', url: '/onboarding/signout' },
];
