/* Dependencies */
import { FunctionComponent, PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';

/**
 * Auth Route Guard
 * @param props - Required component props.
 * @returns
 */
export const AuthRouteGuard: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  if (!sessionStorage.getItem('authToken')) {
    return <Navigate to="/onboarding/signin" />;
  }

  return children as JSX.Element;
};
