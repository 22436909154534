/* Dependencies */
import { FunctionComponent, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

// Views
import { SignInView } from './views/SignIn/SignIn.view';
import { SignOutView } from './views/SignOut/SignOut.view';

// Models

/**
 * Onboarding container
 * @param props - Required component props.
 * @returns
 */
export const OnboardingContainer: FunctionComponent = () => {
  useEffect(() => {
    document.title = 'Envelo | Onboarding';
  }, []);

  return (
    <main className="w-full flex flex-row flex-wrap min-h-screen justify-center items-center px-4">
      <div className="w-full max-w-lg mx-auto">
        <div className="w-full">
          <img
            src="/envelo-logo.png"
            alt="logo"
            className="mx-auto block max-w-xs"
          />
          <h1 className="text-3xl mt-4">Sign In</h1>
        </div>
        <Routes>
          <Route path={`signin`} element={<SignInView />} />
          <Route path={`signout`} element={<SignOutView />} />
          <Route path={`*`} element={<Navigate replace to={`signin`} />} />
        </Routes>
      </div>
    </main>
  );
};
