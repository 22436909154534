/* Dependencies */
// Services
import { AuthService } from './modules/Auth/Auth.service';
import { FormsService } from './modules/Forms/Forms.service';

/**
 * HTTP Client
 * @class
 */
class HTTPClient {
  auth: AuthService;
  forms: FormsService;

  constructor() {
    this.auth = new AuthService();
    this.forms = new FormsService();
  }
}

// Export shared http client.
export const httpClient = new HTTPClient();
