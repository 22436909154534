/* Dependencies */
import React, { useEffect, useState } from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/outline';

// Helpers
import { uniqueId } from '../../../../helpers/uniqueId/uniqueId';
import { getDescribedBy } from '../../../../helpers/getDescribedBy/getDescribedBy';

// Models
import { FilePickerProps } from './FilePicker.model';

/**
 * Input Component
 */
export const FilePicker: React.FC<FilePickerProps> = ({
  autoFocus,
  disabled,
  error,
  label,
  max,
  min,
  multiple,
  name,
  onChange,
  placeholder,
  readOnly,
  required,
  supportText,
}) => {
  // Set The Initial State
  const [id] = useState(uniqueId());
  const [describedBy, setDescribedBy] = useState('');

  // Set the Tool tip on ID Change
  useEffect(() => {
    setDescribedBy(getDescribedBy({ error, id, name, supportText }));
  }, [id, error, name, supportText]);

  // Render the component.
  return (
    <div className="w-full">
      <label htmlFor={id} className="block text-base font-medium text-gray-700">
        {label} {required ? <span className="text-indigo-600">*</span> : null}
      </label>
      <div className="mt-1 relative rounded-md shadow-sm">
        <input
          id={id}
          type="file"
          name={name}
          onChange={(event) => {
            if (event.target.files) {
              onChange({
                target: {
                  name,
                  value: multiple ? event.target.files : event.target.files[0],
                },
              });
            } else {
              onChange({ target: { name, value: null } });
            }
          }}
          aria-invalid={error ? true : false}
          aria-describedby={describedBy}
          max={max}
          min={min}
          required={required}
          placeholder={placeholder}
          readOnly={readOnly}
          disabled={disabled}
          autoFocus={autoFocus}
          multiple={multiple || false}
          className={`${
            error &&
            'border-red-600 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500'
          } block w-full pr-10 rounded-md`}
        />
        {error && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        )}
      </div>

      {error || supportText ? (
        <div className="w-full flex flex-row flex-wrap items-start mt-1">
          {error || supportText ? (
            <small
              className={`${error && 'text-red-600'}`}
              id={`${id}_${name}-help`}
            >
              {!error ? supportText : error}
            </small>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};
