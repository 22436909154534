/* Dependnecies */
import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';

// Slices
import appReducer from './slices/App/App.slice';
import formsReducer from './slices/Forms/Forms.slice';

// Create the redux store
export const store = configureStore({
  reducer: {
    app: appReducer,
    forms: formsReducer,
  },
});

// Export the required types for redux.
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
