/* Dependencies */
import * as yup from 'yup';

// Models
import {
  PeopleMonitor,
  UpdatePeopleMonitorReducer,
} from '../../../../store/slices/Forms/Forms.model';

export interface UpdatePeopleMonitorFormProps {
  /**
   * Site Id
   */
  siteId: string;
  /**
   * Current floor id.
   */
  floorId: string;
  /**
   * Current space id.
   */
  spaceId: string;
  /**
   * The Air monitor being edited.
   */
  data: PeopleMonitor;
  /**
   * Callback function to handle form submission.
   */
  onSubmit: (data: UpdatePeopleMonitorReducer) => void;
}
export interface InitialValues {
  /**
   * Name of the monitor.
   */
  name: string;
  /**
   * Location of the monitor.
   */
  location: string;
  /**
   * Device id
   */
  deviceId: string;
  /**
   * Device type
   */
  deviceType: string;
  /**
   * Serial number of the monitor.
   */
  serialNumber: string;
  /**
   * Photo of the monitor.
   */
  photo: File | null;
  /**
   * Comment of the monitor.
   */
  comment: string;
}

export const UpdatePeopleMonitorFormSchema = yup.object({
  name: yup.string().required('Field is required'),
  location: yup.string().required('Field is required'),
  serialNumber: yup.string().required('Field is required'),
  deviceId: yup.string().required('Field is required'),
  deviceType: yup.string().required('Field is required'),
  photo: yup.mixed().optional(),
  comment: yup.string().optional(),
});
