/* Dependencies */
import { FunctionComponent } from 'react';
import { classNames } from '../../../../helpers/classNames/classNames';

// Models
import { SurfaceTestsTableProps } from './SurfaceTestsTable.model';

/**
 * Surface Tests Table
 * @param props - Required component props.
 */
export const SurfaceTestsTable: FunctionComponent<SurfaceTestsTableProps> = ({
  surfaceTests,
  onDelete,
  onEdit,
}) => {
  return (
    <div className="mt-8 flex flex-col">
      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Markup Code
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Location
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Test Result (RLUs)
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span className="sr-only">Actions</span>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {surfaceTests.map((surfaceTest, surfaceTestIndex) => (
                  <tr
                    key={surfaceTest.surfaceTestId}
                    className={classNames(
                      surfaceTestIndex % 2 === 0 ? '' : 'bg-gray-50',
                      surfaceTest.archived ? 'opacity-50' : ''
                    )}
                  >
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                      {surfaceTest.name}
                    </td>

                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      S{surfaceTest.surfaceTestIndex}
                    </td>

                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {surfaceTest.location}
                    </td>

                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {surfaceTest.testResult}
                    </td>
                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                      <button
                        className="text-indigo-600 hover:text-indigo-900"
                        onClick={() => onEdit(surfaceTest)}
                      >
                        Edit
                        <span className="sr-only">
                          , {surfaceTest.location}
                        </span>
                      </button>
                      <button
                        className="text-indigo-600 hover:text-indigo-900 ml-2"
                        onClick={() => onDelete(surfaceTest)}
                      >
                        Archive
                        <span className="sr-only">
                          , {surfaceTest.location}
                        </span>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
