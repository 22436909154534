/* Dependencies */
import React, { useState, useEffect } from 'react';
import { useFormikContext } from 'formik';

// Models
import { FormErrorMessageProps } from './FormErrorMessage.model';

/**
 * Handles errors on the form and shows a related message.
 * @returns
 */
export const FormErrorMessage: React.FC<FormErrorMessageProps> = ({
  propMatch,
}) => {
  const { errors, touched } = useFormikContext<{ [key: string]: string }>();
  const [errorMessage, setError] = useState<string | null>(null);

  /**
   * Lifecycle - Mount
   */
  useEffect(() => {
    const fieldsToFix: string[] = [];

    // Form the fields to fix.
    Object.keys(errors).forEach((key) => {
      if (key && touched[key]) {
        fieldsToFix.push(propMatch[key] || key);
      }
    });

    // Form the error messages.
    if (fieldsToFix.length) {
      setError(`Please fix the following fields: ${fieldsToFix.join(', ')}.`);
      return;
    }

    setError(null);
  }, [errors, touched, propMatch]);

  return (
    <>
      {errorMessage && (
        <div
          className="w-full mb-4 p-4 bg-red-600 text-white rounded overflow-hidden block"
          aria-live="assertive"
          aria-atomic="true"
        >
          <h3 className="text-lg font-medium">Form Errors Detected</h3>
          <p>{errorMessage}</p>
        </div>
      )}
    </>
  );
};
