/* Dependencies */
import { FunctionComponent, useEffect, useState } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router';

// Services
import { httpClient } from '../../../../services/httpClient';

// Views
import { CreateSiteView } from './CreateSiteView/CreateSiteView';
import { FloorView } from './FloorView/FloorView';
import { SiteView } from './SiteView/SiteView';

// Components
import { Button } from '../../../../components/Atoms/Button/Button';
import { FloorPlans } from '../../../../components/Molecules/Messaging/FloorPlans/FloorPlans';
import { Modal } from '../../../../components/Molecules/Modal/Modal';
import { CompleteInstallForm } from '../../../../components/Organisms/Forms/CompleteInstallForm/CompleteInstallForm';
import { UpdateSiteForm } from '../../../../components/Organisms/Forms/UpdateSiteForm/UpdateSiteForm';

// Redux
import { useAppSelector, useAppDispatch } from '../../../../store/hooks/hooks';
import {
  removeSite,
  restoreSite,
  updateSite,
} from '../../../../store/slices/Forms/Forms.slice';

/**
 * Install Survey View
 * @returns
 */
export const InstallSurveyView: FunctionComponent = () => {
  const [showFinaliseInstall, setShowFinaliseInstall] = useState(false);
  const [showFloorPlanModal, setShowFloorPlanModal] = useState(false);
  const [showUpdateSite, setShowUpdateSite] = useState(false);
  const installSurvey = useAppSelector((state) => state.forms.installSurvey);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Envelo | Forms | Install Survey';
    const cachedInstallSurvey = sessionStorage.getItem('installSurvey');

    // Restore the cached install survey
    if (cachedInstallSurvey !== null) {
      dispatch(restoreSite(JSON.parse(cachedInstallSurvey)));
    }
  }, [dispatch]);

  useEffect(() => {
    if (installSurvey.siteId) {
      sessionStorage.setItem('installSurvey', JSON.stringify(installSurvey));
    }
  }, [installSurvey]);
  return (
    <>
      <div className="w-full bg-indigo-700 py-2 px-4 rounded">
        <div className="flex flex-row items-center justify-between">
          <h1 className="text-2xl text-white">
            {installSurvey.siteId ? 'Installation Started' : 'Install Survey'}
          </h1>
          {installSurvey.siteId && (
            <Button
              title="Edit Site Details"
              ariaLabel="Edit Site Details"
              icon="edit"
              type="white"
              iconOnly={true}
              size="small"
              onClick={() => {
                setShowUpdateSite(true);
              }}
            />
          )}
        </div>
        {installSurvey.siteId && (
          <>
            <p className="text-sm text-white">
              Site Name: {installSurvey.siteName} | Customer:{' '}
              {installSurvey.customerName}
            </p>

            <div className="w-full mt-2">
              <Button
                title="Finalise Install"
                ariaLabel="Finalise Install"
                type="white"
                onClick={() => {
                  setShowFinaliseInstall(true);
                }}
              />
            </div>
          </>
        )}
      </div>

      {installSurvey.siteId && (
        <div className="w-full bg-blue-600 my-2 rounded py-2 px-4 text-white">
          <p className="text-sm">
            For additional guidance on Floor plan layouts. Please refer to the
            Floor plan instructions here:{' '}
            <button
              className="text-sm underline text-white"
              onClick={() => setShowFloorPlanModal(true)}
            >
              Floor Plan Details
            </button>
          </p>
        </div>
      )}

      <div className="w-full mt-6">
        <Routes>
          <Route
            path={`create-site`}
            element={<CreateSiteView nextStep={() => navigate('site')} />}
          />
          <Route path={`site`} element={<SiteView />} />
          <Route path={`floor/:floorId/*`} element={<FloorView />} />
          <Route path={`*`} element={<Navigate replace to={`create-site`} />} />
        </Routes>
      </div>

      {/* Floor Plans Modal */}
      <Modal
        title="Floor Plan Instructions"
        isOpen={showFloorPlanModal}
        onClose={() => setShowFloorPlanModal(false)}
        isLarge={true}
      >
        <FloorPlans />
      </Modal>
      {/* / Floor Plans Modal */}

      {/* Update Site */}
      <Modal
        title="Update Site Details"
        isOpen={showUpdateSite}
        onClose={() => setShowUpdateSite(false)}
      >
        <UpdateSiteForm
          data={installSurvey}
          onSubmit={(data) => {
            dispatch(updateSite(data));
            setShowUpdateSite(false);
          }}
        />
      </Modal>
      {/* / Update Site */}

      {/* Finalise Install */}
      <Modal
        title="Finalise Install"
        description="Are you sure you want to finalise the install?"
        isOpen={showFinaliseInstall}
        onClose={() => setShowFinaliseInstall(false)}
      >
        <CompleteInstallForm
          siteId={installSurvey.siteId}
          onSubmit={async (sitePlanIds) => {
            await httpClient.forms
              .installForm({
                installForm: {
                  ...installSurvey,
                  sitePlanIds: sitePlanIds,
                },
              })
              .then(() => {
                alert('Install Form Submitted Successfully');
                setShowFinaliseInstall(false);
                sessionStorage.removeItem('installSurvey');
                dispatch(removeSite());
                navigate(`/forms/install-survey`);
              })
              .catch(() => {
                alert('Something went wrong. Please try again.');
              });
          }}
        />
      </Modal>
      {/* / Finalise Install */}
    </>
  );
};
