/* Dependencies */
import { FunctionComponent, useEffect } from 'react';
import { useNavigate } from 'react-router';

/**
 * Sign Out View
 */
export const SignOutView: FunctionComponent = () => {
  const navigate = useNavigate();

  useEffect(() => {
    sessionStorage.removeItem('authToken');
    navigate('/');
  }, [navigate]);

  return <></>;
};
