/* Dependencies */
import * as yup from 'yup';

// Models
import {
  InstallSurvey,
  UpdateSiteReducer,
} from '../../../../store/slices/Forms/Forms.model';

export interface UpdateSiteFormProps {
  /**
   * Data to be rendered.
   */
  data: InstallSurvey;
  /**
   * Callback function to handle form submission.
   */
  onSubmit: (data: UpdateSiteReducer) => void;
}

export const UpdateSiteFormSchema = yup.object({
  siteName: yup.string().required('Field is required'),
  customerName: yup.string().required('Field is required'),
});
