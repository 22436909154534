/* Dependencies */
import { FunctionComponent } from 'react';
import { Formik } from 'formik';
import { v4 as uuid } from 'uuid';

// Services
import { httpClient } from '../../../../services/httpClient';

// Helpers
import { handleFieldError } from '../../../../helpers/handleError/handleFieldError';
import { getSurfaceTestVideoLink } from '../../../../helpers/getSurfaceTestVideoLink/getSurfaceTestVideoLink';

// Components
import { Button } from '../../../Atoms/Button/Button';
import { FormErrorMessage } from '../../../Molecules/Forms/FormErrorMessage/FormErrorMessage';
import { Input } from '../../../Molecules/Forms/Input/Input';
import { FilePicker } from '../../../Molecules/Forms/FilePicker/FilePicker';
import { TextArea } from '../../../Molecules/Forms/TextArea/TextArea';
import { Select } from '../../../Molecules/Forms/Select/Select';
import { What3WordsInput } from '../../../Molecules/Forms/What3WordsInput/What3WordsInput';

// Models
import {
  CreateSurfaceTestFormProps,
  CreateSurfaceTestFormSchema,
  InitialValues,
  SurfaceTestDevice,
  surfaceTestDevices,
} from './CreateSurfaceTestForm.model';

/**
 * Create Surface Test Form
 * @param props - Required component props.
 */
export const CreateSurfaceTestForm: FunctionComponent<
  CreateSurfaceTestFormProps
> = ({ siteId, floorId, spaceId, index, onSubmit }) => {
  const initialValues: InitialValues = {
    name: `Surface Test ${index}`,
    location: '',
    deviceType: surfaceTestDevices[0].value,
    photo: null,
    comment: '',
    testResult: '',
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={CreateSurfaceTestFormSchema}
      onSubmit={async (values) => {
        let imageId = uuid();

        // Upload Photo
        await httpClient.forms.installFormAttachment({
          surveyId: siteId,
          imageId: imageId,
          image: values.photo as File,
        });

        onSubmit({
          floorId,
          spaceId,
          surfaceTestIndex: index,
          name: values.name,
          deviceType: values.deviceType,
          location: values.location,
          photoId: imageId,
          comment: values.comment,
          testResult: values.testResult,
          archived: false,
        });
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => {
        const surfaceTestVideoLink = getSurfaceTestVideoLink(
          values.deviceType as SurfaceTestDevice
        );
        return (
          <>
            <FormErrorMessage
              propMatch={{
                name: 'Name',
                location: 'Location',
                deviceType: 'Device Type',
                serialNumber: 'Serial Number',
                photo: 'Photo',
                workflows: 'Workflows',
                comment: 'Comment',
              }}
            />

            <form
              className="w-full flex flex-row flex-wrap items-end"
              onSubmit={handleSubmit}
            >
              {/* Name */}
              <div className="w-full">
                <Input
                  label="Name"
                  name={`name`}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.name}
                  type="text"
                  inputMode="text"
                  required={true}
                  readOnly={true}
                  error={handleFieldError(errors, touched, `name`)}
                />
              </div>
              {/* / Name */}

              {/* Markup Note */}
              <div className="w-full bg-indigo-700 text-white px-4 py-2 my-2 rounded">
                <p className="text-sm">Markup On Floor Plan: S{index}</p>
              </div>
              {/* / Markup Note */}

              {/* Device Type */}
              <div className="w-full mt-2">
                <Select
                  label="Device Type"
                  name={`deviceType`}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.deviceType}
                  required={true}
                  error={handleFieldError(errors, touched, `deviceType`)}
                  options={surfaceTestDevices}
                />
              </div>
              {/* / Device Type */}

              {/* Test Guide */}
              {surfaceTestVideoLink && (
                <div className="w-full my-2">
                  <p className="text-base">
                    <a
                      href={surfaceTestVideoLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="underline"
                    >
                      View Surface Test Guide
                    </a>
                  </p>
                </div>
              )}
              {/* / Test Guide */}

              {/* Location */}
              <div className="w-full mt-2">
                <What3WordsInput
                  label="Location"
                  name={`location`}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.location}
                  required={true}
                  error={handleFieldError(errors, touched, `location`)}
                />
              </div>
              {/* / Location */}

              {/* Photo */}
              <div className="w-full mt-4">
                <FilePicker
                  label="Photo"
                  name={`photo`}
                  onChange={handleChange}
                  value={values.photo as File}
                  required={true}
                  error={handleFieldError(errors, touched, `photo`)}
                />
              </div>
              {/* / Photo */}

              {/* Test Result */}
              <div className="w-full mt-4">
                <Input
                  label="Test Result (RLUs)"
                  name={`testResult`}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.testResult}
                  type="text"
                  inputMode="text"
                  required={true}
                  error={handleFieldError(errors, touched, `testResult`)}
                />
              </div>
              {/* / Test Result */}

              {/* Comment */}
              <div className="w-full mt-4">
                <TextArea
                  label="Comment"
                  name={`comment`}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.comment}
                  error={handleFieldError(errors, touched, `comment`)}
                />
              </div>
              {/* / Comment */}

              {/* Submit */}
              <div className="w-full flex flex-row flex-wrap justify-end mt-2">
                <Button
                  title="Create"
                  ariaLabel="Create Surface Test"
                  role="submit"
                  type="primary"
                  size="medium"
                  onClick={handleSubmit}
                />
              </div>
              {/* Submit */}
            </form>
          </>
        );
      }}
    </Formik>
  );
};
