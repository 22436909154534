/* Dependencies */
import React, { useEffect, useState } from 'react';

// Helpers
import { uniqueId } from '../../../../helpers/uniqueId/uniqueId';
import { getDescribedBy } from '../../../../helpers/getDescribedBy/getDescribedBy';

// Models
import { ToggleProps } from './Toggle.model';

/**
 * Toggle Component
 * @param props - Required component props.
 */
export const Toggle: React.FC<ToggleProps> = ({
  checked,
  disabled,
  error,
  label,
  name,
  onChange,
  onBlur,
  readOnly,
  required,
  supportText,
  value,
}) => {
  // Set The Initial State
  const [id] = useState(uniqueId());
  const [describedBy, setDescribedBy] = useState('');

  // Set the Tool tip on ID Change
  useEffect(() => {
    setDescribedBy(getDescribedBy({ error, id, name, supportText }));
  }, [error, id, name, supportText]);

  // Render the component.
  return (
    <div className="relative flex items-start flex-wrap">
      <label
        htmlFor={id}
        className={`inline-flex relative items-center cursor-pointer ${
          error && 'text-red-600'
        }`}
      >
        <input
          id={id}
          type="checkbox"
          name={name}
          onChange={onChange.bind(this)}
          onBlur={onBlur.bind(this)}
          aria-invalid={error ? true : false}
          aria-describedby={describedBy}
          required={required}
          readOnly={readOnly}
          disabled={disabled}
          value={value}
          defaultChecked={checked}
          className="h-4 w-4 rounded sr-only peer"
        />
        <div className="relative px-4 w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-30 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-indigo-600"></div>
        <span className="ml-3 text-base ">
          {' '}
          {label} {required ? <span className="text-primary">*</span> : null}
        </span>
      </label>

      {error || supportText ? (
        <div className="w-full flex flex-row flex-wrap items-start mt-1">
          {error || supportText ? (
            <small
              className={`${error && 'text-red-600'}`}
              id={`${id}_${name}-help`}
            >
              {!error ? supportText : error}
            </small>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};
