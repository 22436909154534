/* Dependencies */
import * as yup from 'yup';

// Models
import {
  SurfaceTest,
  UpdateSurfaceTestReducer,
} from '../../../../store/slices/Forms/Forms.model';

export interface UpdateSurfaceTestFormProps {
  /**
   * Site Id
   */
  siteId: string;
  /**
   * Current floor id.
   */
  floorId: string;
  /**
   * Current space id.
   */
  spaceId: string;
  /**
   * Surface test to be updated.
   */
  data: SurfaceTest;
  /**
   * Callback function to handle form submission.
   */
  onSubmit: (data: UpdateSurfaceTestReducer) => void;
}
export interface InitialValues {
  /**
   * Name of the surface test.
   */
  name: string;
  /**
   * Location of the air monitor.
   */
  location: string;
  /**
   * Device type
   */
  deviceType: string;
  /**
   * Photo of the air monitor.
   */
  photo: File | null;
  /**
   * Comment of the air monitor.
   */
  comment: string;
  /**
   * Test result.
   */
  testResult: string;
}

export const UpdateSurfaceTestFormSchema = yup.object({
  name: yup.string().required('Field is required'),
  location: yup.string().required('Field is required'),
  photo: yup.mixed().optional(),
  comment: yup.string().optional(),
  testResult: yup.string().required('Field is required'),
});
