/* Dependencies */
import * as yup from 'yup';

// Models
import { KeyValue } from '../../../../models/KeyValue';
import { CreatePeopleMonitorReducer } from '../../../../store/slices/Forms/Forms.model';

export const peopleMontitorTypes: KeyValue[] = [
  { key: 'Ubiqisense heat map', value: 'Ubiqisense heat map' },
  { key: 'Ubiqisense people counter', value: 'Ubiqisense people counter' },
  { key: 'Vergesense heat map', value: 'Vergesense heat map' },
  { key: 'Vergesense people counter', value: 'Vergesense people counter' },
  { key: 'Vergesense IP camera', value: 'Vergesense IP camera' },
  { key: 'Skyfii heat map', value: 'Skyfii heat map' },
  { key: 'Skyfii people counter', value: 'Skyfii people counter' },
  { key: 'Pressac people counter', value: 'Pressac people counter' },
];

export type PeopleMonitorType =
  | 'Ubiqisense heat map'
  | 'Ubiqisense people counter'
  | 'Vergesense heat map'
  | 'Vergesense people counter'
  | 'Vergesense IP camera'
  | 'Skyfii heat map'
  | 'Skyfii people counter'
  | 'Pressac people counter';

export interface CreatePeopleMonitorFormProps {
  /**
   * Site Id
   */
  siteId: string;
  /**
   * Current floor id.
   */
  floorId: string;
  /**
   * Current space id.
   */
  spaceId: string;
  /**
   * Index
   */
  index: number;
  /**
   * Callback function to handle form submission.
   */
  onSubmit: (data: CreatePeopleMonitorReducer) => void;
}

export interface InitialValues {
  /**
   * Name of the monitor.
   */
  name: string;
  /**
   * Location of the monitor.
   */
  location: string;
  /**
   * Device id
   */
  deviceId: string;
  /**
   * Device type
   */
  deviceType: string;
  /**
   * Serial number of the monitor.
   */
  serialNumber: string;
  /**
   * Photo of the monitor.
   */
  photo: File | null;
  /**
   * Comment of the monitor.
   */
  comment: string;
}

export const CreatePeopleMonitorFormSchema = yup.object({
  name: yup.string().required('Field is required'),
  location: yup.string().required('Field is required'),
  deviceId: yup.string().required('Field is required'),
  deviceType: yup.string().required('Field is required'),
  serialNumber: yup.string().required('Field is required'),
  photo: yup.mixed().required('Field is required'),
  comment: yup.string().optional(),
});
