/* Dependencies */
import { FunctionComponent } from 'react';
import { Formik } from 'formik';

// Helpers
import { handleFieldError } from '../../../../helpers/handleError/handleFieldError';

// Components
import { Button } from '../../../Atoms/Button/Button';
import { FormErrorMessage } from '../../../Molecules/Forms/FormErrorMessage/FormErrorMessage';
import { Input } from '../../../Molecules/Forms/Input/Input';
import { TextArea } from '../../../Molecules/Forms/TextArea/TextArea';
import { ToggleList } from '../../../Molecules/Forms/ToggleList/ToggleList';

// Models
import {
  CreateSpaceFormProps,
  CreateSpaceFormSchema,
  InitialValues,
  workflows,
} from './CreateSpaceForm.model';

/**
 * Floor Form
 * @param props - Required component props.
 */
export const CreateSpaceForm: FunctionComponent<CreateSpaceFormProps> = ({
  floorId,
  onSubmit,
}) => {
  const initialValues: InitialValues = {
    spaceName: '',
    workflows: [],
    comment: '',
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={CreateSpaceFormSchema}
      onSubmit={(values) => {
        onSubmit({
          floorId,
          spaceName: values.spaceName,
          workflows: values.workflows,
          comment: values.comment,
        });
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <>
          <FormErrorMessage
            propMatch={{
              spaceName: 'Space Name',
              workflows: 'Workflows',
              comment: 'Comment',
            }}
          />

          <form
            className="w-full flex flex-row flex-wrap items-end"
            onSubmit={handleSubmit}
          >
            {/* Space Name */}
            <div className="w-full">
              <Input
                label="Space Name"
                name="spaceName"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.spaceName}
                type="text"
                inputMode="text"
                error={handleFieldError(errors, touched, 'spaceName')}
                required={true}
              />
            </div>
            {/* / Space Name */}

            {/* Workflows */}
            <div className="w-full mt-4">
              <ToggleList
                title="Workflows"
                required={true}
                error={handleFieldError(errors, touched, `workflows`)}
                items={workflows.map((workflow) => {
                  return {
                    label: workflow.key,
                    value: workflow.value,
                    checked: values.workflows.includes(workflow.value),
                    onBlur: handleBlur,
                    onChange: handleChange,
                    name: `workflows`,
                  };
                })}
              />
            </div>
            {/* / Workflows */}

            {/* Comment */}
            <div className="w-full mt-4">
              <TextArea
                label="Comment"
                name={`comment`}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.comment}
                error={handleFieldError(errors, touched, `comment`)}
              />
            </div>
            {/* / Comment */}

            {/* Submit */}
            <div className="w-full flex flex-row flex-wrap justify-end mt-2">
              <Button
                title="Create"
                ariaLabel="Create"
                role="submit"
                type="primary"
                size="medium"
                onClick={handleSubmit}
              />
              {/* Submit */}
            </div>
          </form>
        </>
      )}
    </Formik>
  );
};
