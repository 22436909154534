/* Dependencies */
import { FunctionComponent } from 'react';
import { Dialog } from '@headlessui/react';

// Models
import { ModalProps } from './Modal.model';
import { XIcon } from '@heroicons/react/outline';

/**
 * Modal
 * @param props - Required component props.
 */
export const Modal: FunctionComponent<ModalProps> = ({
  title,
  description,
  isOpen,
  onClose,
  children,
  isLarge,
}) => {
  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-50">
      <div className="fixed inset-0 bg-black opacity-30" aria-hidden="true" />
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel
          className={`mx-auto w-full ${
            isLarge ? 'max-w-2xl' : 'max-w-md'
          } rounded bg-white p-4 max-h-full overflow-auto relative`}
        >
          <Dialog.Title className="text-lg font-semibold">{title}</Dialog.Title>
          {description && (
            <Dialog.Description className="my-2 text-base">
              {description}
            </Dialog.Description>
          )}

          {children}

          <div className="block absolute top-4 right-4">
            <button
              type="button"
              className="bg-white text-gray-800 hover:bg-gray-800 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500  rounded-full border-gray-800 border"
              onClick={() => onClose()}
            >
              <span className="sr-only">Close</span>
              <XIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};
