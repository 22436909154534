/* Dependencies */
import what3words, {
  ApiVersion,
  Transport,
  What3wordsService,
  fetchTransport,
} from '@what3words/api';

// Create transport service
const transport: Transport = fetchTransport();

// Create and export client.
export const what3wordsClient: What3wordsService = what3words(
  process.env.REACT_APP_WHAT_3_WORDS_API_KEY as string,
  { host: 'https://api.what3words.com', apiVersion: ApiVersion.Version3 },
  {
    transport,
  }
);
