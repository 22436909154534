/* Dependencies */
import { FunctionComponent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Button } from '../../../../../components/Atoms/Button/Button';

// Components
import { Modal } from '../../../../../components/Molecules/Modal/Modal';
import { Placeholder } from '../../../../../components/Molecules/Placeholder/Placeholder';
import { ActionList } from '../../../../../components/Organisms/ActionList/ActionList';
import { CreateFloorForm } from '../../../../../components/Organisms/Forms/CreateFloorForm/CreateFloorForm';
import { IntroTextWithButton } from '../../../../../components/Organisms/IntroTextWithButton/IntroTextWithButton';

// Redux
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../store/hooks/hooks';
import {
  createFloor,
  removeSite,
} from '../../../../../store/slices/Forms/Forms.slice';

/**
 * Site View
 * @returns
 */
export const SiteView: FunctionComponent = () => {
  const [showCreateFloorModal, setShowCreateFloorModal] = useState(false);
  const [showDeleteSite, setShowDeleteSite] = useState(false);
  const installSurvey = useAppSelector((state) => state.forms.installSurvey);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (!installSurvey || !installSurvey.siteId) {
      return navigate('/forms/install-survey');
    }
  }, [installSurvey, navigate]);

  return (
    <>
      <div className="mt-4">
        <IntroTextWithButton
          title="Floors & Zones"
          description="Add Floors and any Zones, which are used to organise the Spaces on Site.  Floors are building levels.  Zones are areas other than Floors which have their own separate floorplans (e.g. building wings, annexes or separate accommodation blocks)."
          button={{
            title: 'Add Floor',
            ariaLabel: 'Add Floor',
            type: 'primary',
            onClick: () => {
              setShowCreateFloorModal(true);
            },
          }}
        />
      </div>

      <div className="w-full mt-4">
        {installSurvey.floors.length > 0 && (
          <ActionList
            entries={installSurvey.floors.map((floor) => ({
              title: floor.floorName,
              description: `Spaces: ${floor.spaces.length}`,
              url: `/forms/install-survey/floor/${floor.floorId}/list`,
            }))}
          />
        )}

        {!installSurvey.floors.length && (
          <Placeholder
            icon={'space'}
            title={'Floors'}
            description={'Add a Floor to get started'}
          />
        )}
      </div>

      <hr className="my-6" />

      <div className="w-full mt-6">
        <Button
          title="Discard Install"
          ariaLabel="Discard Install"
          icon="delete"
          type="danger"
          size="medium"
          onClick={() => setShowDeleteSite(true)}
        />
      </div>

      {/* Create Floor Modal */}
      <Modal
        title="Create Floor"
        isOpen={showCreateFloorModal}
        onClose={() => setShowCreateFloorModal(false)}
      >
        <div className="w-full">
          <CreateFloorForm
            onSubmit={(data) => {
              dispatch(createFloor(data));
              setShowCreateFloorModal(false);
            }}
          />
        </div>
      </Modal>
      {/* / Create Floor */}

      {/* Delete Site */}
      <Modal
        title="Discard Install"
        description="Are you sure you want to discard the install? This action cannot be undone."
        isOpen={showDeleteSite}
        onClose={() => setShowDeleteSite(false)}
      >
        <>
          <div className="w-full bg-indigo-700 rounded p-4 text-white">
            <p className="text-base">{installSurvey.siteName}</p>
          </div>
          <div className="w-full mt-4">
            <Button
              title="Discard Install"
              ariaLabel="Discard Install"
              type="danger"
              onClick={() => {
                dispatch(removeSite());
                sessionStorage.removeItem('installSurvey');
                setShowDeleteSite(false);
                navigate('/forms/install-survey');
              }}
            />
          </div>
        </>
      </Modal>
      {/* / Delete Site */}
    </>
  );
};
