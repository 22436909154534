/* Dependencies */
import * as yup from 'yup';

// Models
import { CreateSiteReducer } from '../../../../store/slices/Forms/Forms.model';

export interface CreateSiteFormProps {
  /**
   * Callback function to handle form submission.
   */
  onSubmit: (data: CreateSiteReducer) => void;
}

export const CreateSiteFormSchema = yup.object({
  siteName: yup.string().required('Field is required'),
  customerName: yup.string().required('Field is required'),
});
