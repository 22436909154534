/* Dependencies */
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { v4 as uuid } from 'uuid';

// Models
import {
  CreateAirMonitorReducer,
  CreateFloorReducer,
  CreatePeopleMonitorReducer,
  CreateSiteReducer,
  CreateSpaceReducer,
  CreateSurfaceTestReducer,
  FormsState,
  InstallSurvey,
  RemoveAirMonitorReducer,
  RemoveFloorReducer,
  RemovePeopleMonitorReducer,
  RemoveSpaceReducer,
  RemoveSurfaceTestReducer,
  UpdateAirMonitorReducer,
  UpdateFloorReducer,
  UpdatePeopleMonitorReducer,
  UpdateSiteReducer,
  UpdateSpaceReducer,
  UpdateSpaceWorkflowsReducer,
  UpdateSurfaceTestReducer,
} from './Forms.model';

// Define the initial state
const initialState: FormsState = {
  installSurvey: {
    siteId: '',
    siteName: '',
    sitePlanIds: [],
    customerName: '',
    floors: [],
  },
  surfaceTestIndex: 0,
  airMonitorIndex: 0,
  peopleMonitorIndex: 0,
};

// Creaye the slice
export const formsSlice = createSlice({
  name: 'forms',
  initialState,
  reducers: {
    restoreSite: (state, action) => {
      const payload: InstallSurvey = action.payload;
      return {
        ...state,
        installSurvey: { ...payload },
      };
    },
    createSite: (state, action) => {
      const payload: CreateSiteReducer = action.payload;
      return {
        ...state,
        installSurvey: {
          siteId: payload.siteId,
          siteName: payload.siteName,
          sitePlanIds: payload.sitePlanIds,
          customerName: payload.customerName,
          floors: [],
        },
      };
    },
    updateSite: (state, action) => {
      const payload: UpdateSiteReducer = action.payload;
      return {
        ...state,
        installSurvey: {
          ...state.installSurvey,
          siteName: payload.siteName,
          customerName: payload.customerName,
        },
      };
    },
    removeSite: () => {
      return {
        ...initialState,
      };
    },
    createFloor: (state, action) => {
      const payload: CreateFloorReducer = action.payload;
      return {
        ...state,
        installSurvey: {
          ...state.installSurvey,
          floors: [
            ...state.installSurvey.floors,
            {
              floorId: uuid(),
              floorName: payload.floorName,
              spaces: [],
            },
          ],
        },
      };
    },
    updateFloor: (state, action) => {
      const payload: UpdateFloorReducer = action.payload;
      return {
        ...state,
        installSurvey: {
          ...state.installSurvey,
          floors: state.installSurvey.floors.map((floor) => {
            if (floor.floorId === payload.floorId) {
              return {
                ...floor,
                floorName: payload.floorName,
              };
            }
            return floor;
          }),
        },
      };
    },
    removeFloor: (state, action) => {
      const payload: RemoveFloorReducer = action.payload;
      return {
        ...state,
        installSurvey: {
          ...state.installSurvey,
          floors: state.installSurvey.floors.filter((floor) => {
            return floor.floorId !== payload.floorId;
          }),
        },
      };
    },
    createSpace: (state, action) => {
      const payload: CreateSpaceReducer = action.payload;
      return {
        ...state,
        installSurvey: {
          ...state.installSurvey,
          floors: state.installSurvey.floors.map((floor) => {
            if (floor.floorId === payload.floorId) {
              return {
                ...floor,
                spaces: [
                  ...floor.spaces,
                  {
                    spaceId: uuid(),
                    spaceName: payload.spaceName,
                    workflows: payload.workflows,
                    comment: payload.comment,
                    airMonitors: [],
                    surfaceTests: [],
                    peopleMonitors: [],
                  },
                ],
              };
            }
            return floor;
          }),
        },
      };
    },
    updateSpace: (state, action) => {
      const payload: UpdateSpaceReducer = action.payload;
      return {
        ...state,
        installSurvey: {
          ...state.installSurvey,
          floors: state.installSurvey.floors.map((floor) => {
            if (floor.floorId === payload.floorId) {
              return {
                ...floor,
                spaces: floor.spaces.map((space) => {
                  if (space.spaceId === payload.spaceId) {
                    return {
                      ...space,
                      spaceName: payload.spaceName,
                    };
                  }
                  return space;
                }),
              };
            }
            return floor;
          }),
        },
      };
    },
    updateSpaceWorkflows: (state, action) => {
      const payload: UpdateSpaceWorkflowsReducer = action.payload;
      return {
        ...state,
        installSurvey: {
          ...state.installSurvey,
          floors: state.installSurvey.floors.map((floor) => {
            if (floor.floorId === payload.floorId) {
              return {
                ...floor,
                spaces: floor.spaces.map((space) => {
                  if (space.spaceId === payload.spaceId) {
                    return {
                      ...space,
                      workflows: payload.workflows,
                      comment: payload.comment,
                    };
                  }
                  return space;
                }),
              };
            }
            return floor;
          }),
        },
      };
    },
    removeSpace: (state, action) => {
      const payload: RemoveSpaceReducer = action.payload;
      return {
        ...state,
        installSurvey: {
          ...state.installSurvey,
          floors: state.installSurvey.floors.map((floor) => {
            if (floor.floorId === payload.floorId) {
              return {
                ...floor,
                spaces: floor.spaces.filter((space) => {
                  return space.spaceId !== payload.spaceId;
                }),
              };
            }
            return floor;
          }),
        },
      };
    },
    createAirMonitor: (state, action) => {
      const payload: CreateAirMonitorReducer = action.payload;
      return {
        ...state,
        installSurvey: {
          ...state.installSurvey,
          floors: state.installSurvey.floors.map((floor) => {
            if (floor.floorId === payload.floorId) {
              return {
                ...floor,
                spaces: floor.spaces.map((space) => {
                  if (space.spaceId === payload.spaceId) {
                    return {
                      ...space,
                      airMonitors: [
                        ...space.airMonitors,
                        {
                          airMonitorId: uuid(),
                          ...payload,
                        },
                      ],
                    };
                  }
                  return space;
                }),
              };
            }
            return floor;
          }),
        },
      };
    },
    updateAirMonitor: (state, action) => {
      const payload: UpdateAirMonitorReducer = action.payload;
      return {
        ...state,
        installSurvey: {
          ...state.installSurvey,
          floors: state.installSurvey.floors.map((floor) => {
            if (floor.floorId === payload.floorId) {
              return {
                ...floor,
                spaces: floor.spaces.map((space) => {
                  if (space.spaceId === payload.spaceId) {
                    return {
                      ...space,
                      airMonitors: space.airMonitors.map((airMonitor) => {
                        if (airMonitor.airMonitorId === payload.airMonitorId) {
                          return payload;
                        }
                        return airMonitor;
                      }),
                    };
                  }
                  return space;
                }),
              };
            }
            return floor;
          }),
        },
      };
    },
    removeAirMonitor: (state, action) => {
      const payload: RemoveAirMonitorReducer = action.payload;
      return {
        ...state,
        installSurvey: {
          ...state.installSurvey,
          floors: state.installSurvey.floors.map((floor) => {
            if (floor.floorId === payload.floorId) {
              return {
                ...floor,
                spaces: floor.spaces.map((space) => {
                  if (space.spaceId === payload.spaceId) {
                    return {
                      ...space,
                      airMonitors: space.airMonitors.map((airMonitor) => {
                        if (airMonitor.airMonitorId === payload.airMonitorId) {
                          return {
                            ...airMonitor,
                            archived: true,
                          };
                        }
                        return airMonitor;
                      }),
                    };
                  }
                  return space;
                }),
              };
            }
            return floor;
          }),
        },
      };
    },
    createSurfaceTest: (state, action) => {
      const payload: CreateSurfaceTestReducer = action.payload;
      return {
        ...state,
        installSurvey: {
          ...state.installSurvey,
          floors: state.installSurvey.floors.map((floor) => {
            if (floor.floorId === payload.floorId) {
              return {
                ...floor,
                spaces: floor.spaces.map((space) => {
                  if (space.spaceId === payload.spaceId) {
                    return {
                      ...space,
                      surfaceTests: [
                        ...space.surfaceTests,
                        { surfaceTestId: uuid(), ...payload },
                      ],
                    };
                  }
                  return space;
                }),
              };
            }
            return floor;
          }),
        },
      };
    },
    updateSurfaceTest: (state, action) => {
      const payload: UpdateSurfaceTestReducer = action.payload;
      return {
        ...state,
        installSurvey: {
          ...state.installSurvey,
          floors: state.installSurvey.floors.map((floor) => {
            if (floor.floorId === payload.floorId) {
              return {
                ...floor,
                spaces: floor.spaces.map((space) => {
                  if (space.spaceId === payload.spaceId) {
                    return {
                      ...space,
                      surfaceTests: space.surfaceTests.map((surfaceTest) => {
                        if (
                          surfaceTest.surfaceTestId === payload.surfaceTestId
                        ) {
                          return payload;
                        }
                        return surfaceTest;
                      }),
                    };
                  }
                  return space;
                }),
              };
            }
            return floor;
          }),
        },
      };
    },
    removeSurfaceTest: (state, action) => {
      const payload: RemoveSurfaceTestReducer = action.payload;
      return {
        ...state,
        installSurvey: {
          ...state.installSurvey,
          floors: state.installSurvey.floors.map((floor) => {
            if (floor.floorId === payload.floorId) {
              return {
                ...floor,
                spaces: floor.spaces.map((space) => {
                  if (space.spaceId === payload.spaceId) {
                    return {
                      ...space,
                      surfaceTests: space.surfaceTests.map((surfaceTest) => {
                        if (
                          surfaceTest.surfaceTestId === payload.surfaceTestId
                        ) {
                          return {
                            ...surfaceTest,
                            archived: true,
                          };
                        }
                        return surfaceTest;
                      }),
                    };
                  }
                  return space;
                }),
              };
            }
            return floor;
          }),
        },
      };
    },
    createPeopleMonitor: (state, action) => {
      const payload: CreatePeopleMonitorReducer = action.payload;
      return {
        ...state,
        installSurvey: {
          ...state.installSurvey,
          floors: state.installSurvey.floors.map((floor) => {
            if (floor.floorId === payload.floorId) {
              return {
                ...floor,
                spaces: floor.spaces.map((space) => {
                  if (space.spaceId === payload.spaceId) {
                    return {
                      ...space,
                      peopleMonitors: [
                        ...space.peopleMonitors,
                        {
                          peopleMonitorId: uuid(),
                          ...payload,
                        },
                      ],
                    };
                  }
                  return space;
                }),
              };
            }
            return floor;
          }),
        },
      };
    },
    updatePeopleMonitor: (state, action) => {
      const payload: UpdatePeopleMonitorReducer = action.payload;
      return {
        ...state,
        installSurvey: {
          ...state.installSurvey,
          floors: state.installSurvey.floors.map((floor) => {
            if (floor.floorId === payload.floorId) {
              return {
                ...floor,
                spaces: floor.spaces.map((space) => {
                  if (space.spaceId === payload.spaceId) {
                    return {
                      ...space,
                      peopleMonitors: space.peopleMonitors.map(
                        (peopleMonitor) => {
                          if (
                            peopleMonitor.peopleMonitorId ===
                            payload.peopleMonitorId
                          ) {
                            return payload;
                          }
                          return peopleMonitor;
                        }
                      ),
                    };
                  }
                  return space;
                }),
              };
            }
            return floor;
          }),
        },
      };
    },
    removePeopleMonitor: (state, action) => {
      const payload: RemovePeopleMonitorReducer = action.payload;
      return {
        ...state,
        installSurvey: {
          ...state.installSurvey,
          floors: state.installSurvey.floors.map((floor) => {
            if (floor.floorId === payload.floorId) {
              return {
                ...floor,
                spaces: floor.spaces.map((space) => {
                  if (space.spaceId === payload.spaceId) {
                    return {
                      ...space,
                      peopleMonitors: space.peopleMonitors.map(
                        (peopleMonitor) => {
                          if (
                            peopleMonitor.peopleMonitorId ===
                            payload.peopleMonitorId
                          ) {
                            return {
                              ...peopleMonitor,
                              archived: true,
                            };
                          }
                          return peopleMonitor;
                        }
                      ),
                    };
                  }
                  return space;
                }),
              };
            }
            return floor;
          }),
        },
      };
    },
    incrementAirMonitorsIndex: (state) => {
      return {
        ...state,
        airMonitorIndex: state.airMonitorIndex + 1,
      };
    },
    incrementPeopleMonitorsIndex: (state) => {
      return {
        ...state,
        peopleMonitorIndex: state.peopleMonitorIndex + 1,
      };
    },
    incrementSurfaceTestsIndex: (state) => {
      return {
        ...state,
        surfaceTestIndex: state.surfaceTestIndex + 1,
      };
    },
  },
});

// Export actions
export const {
  restoreSite,
  createSite,
  updateSite,
  removeSite,
  createFloor,
  updateFloor,
  removeFloor,
  createSpace,
  updateSpace,
  updateSpaceWorkflows,
  removeSpace,
  createAirMonitor,
  updateAirMonitor,
  removeAirMonitor,
  createSurfaceTest,
  updateSurfaceTest,
  removeSurfaceTest,
  createPeopleMonitor,
  updatePeopleMonitor,
  removePeopleMonitor,
  incrementAirMonitorsIndex,
  incrementPeopleMonitorsIndex,
  incrementSurfaceTestsIndex,
} = formsSlice.actions;

// Export selectors
export const selectInstallForm = (state: RootState) =>
  state.forms.installSurvey;

export const selectAirMonitorsIndex = (state: RootState) =>
  state.forms.airMonitorIndex;

export const selectPeopleMonitorsIndex = (state: RootState) =>
  state.forms.peopleMonitorIndex;

export const selectSurfaceTestsIndex = (state: RootState) =>
  state.forms.surfaceTestIndex;

// Export reducer
export default formsSlice.reducer;
