/* Dependencies */
import { FunctionComponent } from 'react';
import { Navigate, Route, Routes } from 'react-router';

// Components
import { DesktopSideBar } from '../../components/Organisms/DesktopSideBar/DesktopSideBar';
import { TopMenu } from '../../components/Organisms/TopMenu/TopMenu';
import { FormsContainer } from '../Forms/Forms.container';

// Models
import { MainProps } from './Main.model';

/**
 * Main container
 * @param props - Required component props.
 * @returns
 */
export const Main: FunctionComponent<MainProps> = () => {
  return (
    <div>
      {/* Static sidebar for desktop */}
      <DesktopSideBar />
      {/* / Static sidebar for desktop */}

      <div className="md:pl-64">
        <div className="max-w-4xl mx-auto flex flex-col md:px-8 xl:px-0">
          {/* Top Menu */}
          <TopMenu />
          {/* / Top Menu */}

          {/* Main */}
          <main className="flex-1 mt-6 px-4 lg:px-0 py-4">
            <Routes>
              <Route path="forms/*" element={<FormsContainer />} />
              <Route path="*" element={<Navigate replace to="/forms" />} />
            </Routes>
          </main>
          {/* / Main */}
        </div>
      </div>
    </div>
  );
};
