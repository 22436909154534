/* Dependencies */
import { Formik } from 'formik';
import { FunctionComponent } from 'react';
import { handleFieldError } from '../../../../helpers/handleError/handleFieldError';
import { Button } from '../../../Atoms/Button/Button';
import { FormErrorMessage } from '../../../Molecules/Forms/FormErrorMessage/FormErrorMessage';

// Components
import { Input } from '../../../Molecules/Forms/Input/Input';
import { Password } from '../../../Molecules/Forms/Password/Password';

// Models
import { SignInFormProps, SignInFormSchema } from './SignInForm.model';

/**
 * Sign In Form
 * @param props - Required component props.
 */
export const SignInForm: FunctionComponent<SignInFormProps> = ({
  failedLogin,
  onSubmit,
}) => {
  return (
    <Formik
      initialValues={{
        username: '',
        password: '',
      }}
      validationSchema={SignInFormSchema}
      onSubmit={(values) => {
        onSubmit({ user: values.username, password: values.password });
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        touched,
      }) => (
        <form onSubmit={handleSubmit}>
          {failedLogin && (
            <div className="w-full p-4 bg-red-600 text-white rounded my-4">
              <p>A user with that username and password could not be found.</p>
            </div>
          )}

          <FormErrorMessage
            propMatch={{
              username: 'Username',
              password: 'Password',
            }}
          />

          <div className="w-full">
            <Input
              label="Username"
              type="text"
              inputMode="text"
              onChange={handleChange}
              onBlur={handleBlur}
              name="username"
              value={values.username}
              error={handleFieldError(errors, touched, 'username')}
              required={true}
            />
          </div>

          <div className="w-full mt-4">
            <Password
              label="Password"
              onChange={handleChange}
              onBlur={handleBlur}
              name="password"
              value={values.password}
              error={handleFieldError(errors, touched, 'password')}
              required={true}
            />
          </div>
          <div className="w-full mt-4">
            <Button
              title="Sign In"
              ariaLabel="Sign In"
              role="submit"
              type="primary"
              size="medium"
            />
          </div>
        </form>
      )}
    </Formik>
  );
};
