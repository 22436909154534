/* Dependencies */
import { FunctionComponent } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

// Containers
import { Main } from './containers/Main/Main';
import { OnboardingContainer } from './containers/Onboarding/Onboarding.container';

// Guards
import { AuthRouteGuard } from './guards/AuthRouteGuard/AuthRouteGuard';

/**
 * App component
 * @returns
 */
const App: FunctionComponent = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="onboarding/*" element={<OnboardingContainer />} />
        <Route
          path="/*"
          element={
            <AuthRouteGuard>
              <Main />
            </AuthRouteGuard>
          }
        />
        <Route path="*" element={<Navigate replace to="/forms" />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
