/* Dependencies */
import { SurfaceTestDevice } from '../../components/Organisms/Forms/CreateSurfaceTestForm/CreateSurfaceTestForm.model';

/**
 * Retrieve the install link for air montors.
 * @param value - The value to be processed.
 * @returns
 */
export const getSurfaceTestVideoLink = (
  value: SurfaceTestDevice
): string | null => {
  switch (value) {
    case 'Hygiena ATP': {
      return 'https://www.youtube.com/watch?v=ujFXlxkZTO8';
    }

    case 'Kikkoman ATP': {
      return 'https://www.youtube.com/watch?v=crZ0jgeC6J8';
    }

    default: {
      return null;
    }
  }
};
