/* Dependencies */
import { FunctionComponent } from 'react';
import { v4 as uuid } from 'uuid';
import { Formik } from 'formik';

// Services
import { httpClient } from '../../../../services/httpClient';

// Helpers
import { handleFieldError } from '../../../../helpers/handleError/handleFieldError';
import { getAirMonitorInstallLink } from '../../../../helpers/getAirMonitorInstallLink/getAirMonitorInstallLink';

// Components
import { Button } from '../../../Atoms/Button/Button';
import { FormErrorMessage } from '../../../Molecules/Forms/FormErrorMessage/FormErrorMessage';
import { Input } from '../../../Molecules/Forms/Input/Input';
import { FilePicker } from '../../../Molecules/Forms/FilePicker/FilePicker';
import { TextArea } from '../../../Molecules/Forms/TextArea/TextArea';
import { Select } from '../../../Molecules/Forms/Select/Select';
import { What3WordsInput } from '../../../Molecules/Forms/What3WordsInput/What3WordsInput';

// Models
import {
  UpdateAirMonitorFormProps,
  UpdateAirMonitorFormSchema,
  InitialValues,
} from './UpdateAirMonitorForm.model';
import {
  AirMonitorType,
  airMontitorTypes,
} from '../CreateAirMonitorForm/CreateAirMonitorForm.model';

/**
 * Update Air Monitor Form
 * @param props - Required component props.
 */
export const UpdateAirMonitorForm: FunctionComponent<
  UpdateAirMonitorFormProps
> = ({ siteId, floorId, spaceId, data, onSubmit }) => {
  const initialValues: InitialValues = {
    location: data.location,
    name: data.name,
    serialNumber: data.serialNumber,
    deviceId: data.deviceId,
    deviceType: data.deviceType,
    photo: null,
    comment: data.comment,
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={UpdateAirMonitorFormSchema}
      onSubmit={async (values) => {
        let imageId = data.photoId;

        if (values.photo) {
          imageId = uuid();

          // Upload Photo
          await httpClient.forms.installFormAttachment({
            surveyId: siteId,
            imageId: imageId,
            image: values.photo as File,
          });
        }

        onSubmit({
          floorId,
          spaceId,
          airMonitorId: data.airMonitorId,
          airMonitorIndex: data.airMonitorIndex,
          name: values.name,
          location: values.location,
          photoId: imageId,
          serialNumber: values.serialNumber,
          deviceId: values.deviceId,
          deviceType: values.deviceType,
          comment: values.comment,
          archived: false,
        });
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => {
        const airMonitorInstallLink = getAirMonitorInstallLink(
          values.deviceType as AirMonitorType
        );

        return (
          <>
            <FormErrorMessage
              propMatch={{
                name: 'Name',
                location: 'Location',
                deviceId: 'Device ID',
                deviceType: 'Device Type',
                serialNumber: 'Serial Number',
                photo: 'Photo',
                comment: 'Comment',
              }}
            />

            <form
              className="w-full flex flex-row flex-wrap items-end"
              onSubmit={handleSubmit}
            >
              {/* Name */}
              <div className="w-full">
                <Input
                  label="Name"
                  name={`name`}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.name}
                  type="text"
                  inputMode="text"
                  required={true}
                  readOnly={true}
                  error={handleFieldError(errors, touched, `name`)}
                />
              </div>
              {/* / Name */}

              {/* Markup Note */}
              <div className="w-full bg-indigo-700 text-white px-4 py-2 my-2 rounded">
                <p className="text-sm">
                  Markup On Floor Plan: M{data.airMonitorIndex}
                </p>
              </div>
              {/* / Markup Note */}

              {/* Location */}
              <div className="w-full mt-2">
                <What3WordsInput
                  label="Location"
                  name={`location`}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.location}
                  required={true}
                  error={handleFieldError(errors, touched, `location`)}
                />
              </div>
              {/* / Location */}

              {/* Device ID */}
              <div className="w-full mt-4">
                <Input
                  label="Device ID"
                  name={`deviceId`}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.deviceId}
                  type="text"
                  inputMode="text"
                  required={true}
                  error={handleFieldError(errors, touched, `deviceId`)}
                />
              </div>
              {/* / Device ID */}

              {/* Device Type */}
              <div className="w-full mt-2">
                <Select
                  label="Device Type"
                  name={`deviceType`}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.deviceType}
                  required={true}
                  error={handleFieldError(errors, touched, `deviceType`)}
                  options={airMontitorTypes}
                />
              </div>
              {/* / Device Type */}

              {/* Install Guide */}
              {airMonitorInstallLink && (
                <div className="w-full my-2">
                  <p className="text-base">
                    <a
                      href={airMonitorInstallLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="underline"
                    >
                      View Install Video Guide
                    </a>
                  </p>
                </div>
              )}
              {/* / Install Guide */}

              {/* Serial Number */}
              <div className="w-full mt-4">
                <Input
                  label="Serial Number"
                  name={`serialNumber`}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.serialNumber}
                  type="text"
                  inputMode="text"
                  required={true}
                  error={handleFieldError(errors, touched, `serialNumber`)}
                />
              </div>
              {/* / Serial Number */}

              {/* Change Photo */}
              <div className="w-full mt-4">
                <FilePicker
                  label="Change Photo"
                  name={`photo`}
                  onChange={handleChange}
                  value={values.photo as File}
                  error={handleFieldError(errors, touched, `photo`)}
                  supportText="Upload photo of back of device displaying device ID and serial number."
                />
              </div>
              {/* / Change Photo */}

              {/* Comment */}
              <div className="w-full mt-4">
                <TextArea
                  label="Comment"
                  name={`comment`}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.comment}
                  error={handleFieldError(errors, touched, `comment`)}
                />
              </div>
              {/* / Comment */}

              {/* Submit */}
              <div className="w-full flex flex-row flex-wrap justify-end mt-2">
                <Button
                  title="Update"
                  ariaLabel="Update Air Monitor"
                  role="submit"
                  type="primary"
                  size="medium"
                  onClick={handleSubmit}
                />
              </div>
              {/* Submit */}
            </form>
          </>
        );
      }}
    </Formik>
  );
};
