/* Dependencies */
import { FunctionComponent } from 'react';
import { Formik } from 'formik';

// Helpers
import { handleFieldError } from '../../../../helpers/handleError/handleFieldError';

// Components
import { Button } from '../../../Atoms/Button/Button';
import { FormErrorMessage } from '../../../Molecules/Forms/FormErrorMessage/FormErrorMessage';
import { ToggleList } from '../../../Molecules/Forms/ToggleList/ToggleList';
import { TextArea } from '../../../Molecules/Forms/TextArea/TextArea';

// Models
import {
  InitialValues,
  UpdateSpaceWorkflowsFormProps,
  UpdateSpaceWorkflowsFormSchema,
} from './UpdateSpaceWorkflowsForm.model';
import { workflows } from '../CreateSpaceForm/CreateSpaceForm.model';

/**
 * Update Space Workflows Form
 * @param props - Required component props.
 */
export const UpdateSpaceWorkflowsForm: FunctionComponent<
  UpdateSpaceWorkflowsFormProps
> = ({ floorId, data, onSubmit }) => {
  const intitialValues: InitialValues = {
    workflows: data.workflows,
    comment: data.comment,
  };

  return (
    <Formik
      initialValues={intitialValues}
      validationSchema={UpdateSpaceWorkflowsFormSchema}
      onSubmit={(values) => {
        onSubmit({
          floorId,
          spaceId: data.spaceId,
          workflows: values.workflows,
          comment: values.comment,
        });
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <>
          <FormErrorMessage
            propMatch={{
              workflows: 'Workflows',
              comment: 'Comment',
            }}
          />

          <form
            className="w-full flex flex-row flex-wrap items-end"
            onSubmit={handleSubmit}
          >
            {/* Workflows */}
            <div className="w-full">
              <ToggleList
                title="Workflows"
                required={true}
                error={handleFieldError(errors, touched, `workflows`)}
                items={workflows.map((workflow) => {
                  return {
                    label: workflow.key,
                    value: workflow.value,
                    checked: values.workflows.includes(workflow.value),
                    onBlur: handleBlur,
                    onChange: handleChange,
                    name: `workflows`,
                  };
                })}
              />
            </div>
            {/* / Workflows */}

            {/* Comment */}
            <div className="w-full mt-4">
              <TextArea
                label="Comment"
                name={`comment`}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.comment}
                error={handleFieldError(errors, touched, `comment`)}
              />
            </div>
            {/* / Comment */}

            {/* Submit */}
            <div className="w-full flex flex-row flex-wrap justify-end mt-4">
              <Button
                title="Update"
                ariaLabel="Update Space"
                role="submit"
                type="primary"
                size="medium"
                onClick={handleSubmit}
              />
              {/* Submit */}
            </div>
          </form>
        </>
      )}
    </Formik>
  );
};
