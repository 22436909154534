/* Dependencies */
import * as yup from 'yup';

// Models
import { KeyValue } from '../../../../models/KeyValue';
import { CreateAirMonitorReducer } from '../../../../store/slices/Forms/Forms.model';

export const airMontitorTypes: KeyValue[] = [
  {
    key: 'AirThings Hub',
    value: 'AirThings Hub',
  },
  {
    key: 'AirThings ViewPlus',
    value: 'AirThings ViewPlus',
  },
  {
    key: 'AirThings WavePlus',
    value: 'AirThings WavePlus',
  },
  {
    key: 'Awair for business',
    value: 'Awair for business',
  },
  {
    key: 'Pressac air monitor',
    value: 'Pressac air monitor',
  },
  {
    key: 'Aranet air monitor',
    value: 'Aranet air monitor',
  },
];

export type AirMonitorType =
  | 'AirThings Hub'
  | 'AirThings ViewPlus'
  | 'AirThings WavePlus'
  | 'Awair for business'
  | 'Pressac air monitor'
  | 'Aranet air monitor';

export interface CreateAirMonitorFormProps {
  /**
   * Site Id
   */
  siteId: string;
  /**
   * Current floor id.
   */
  floorId: string;
  /**
   * Current space id.
   */
  spaceId: string;
  /**
   * Index
   */
  index: number;
  /**
   * Callback function to handle form submission.
   */
  onSubmit: (data: CreateAirMonitorReducer) => void;
}

export interface InitialValues {
  /**
   * Name of the monitor.
   */
  name: string;
  /**
   * Location of the air monitor.
   */
  location: string;
  /**
   * Device id
   */
  deviceId: string;
  /**
   * Device type
   */
  deviceType: string;
  /**
   * Serial number of the air monitor.
   */
  serialNumber: string;
  /**
   * Photo of the air monitor.
   */
  photo: File | null;
  /**
   * Comment of the air monitor.
   */
  comment: string;
}

export const CreateAirMonitorFormSchema = yup.object({
  name: yup.string().required('Field is required'),
  location: yup.string().required('Field is required'),
  deviceId: yup.string().required('Field is required'),
  deviceType: yup.string().required('Field is required'),
  serialNumber: yup.string().required('Field is required'),
  photo: yup.mixed().required('Field is required'),
  comment: yup.string().optional(),
});
