/* Dependencies */
import { FunctionComponent, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Services
import { httpClient } from '../../../../services/httpClient';

// Components
import { SignInForm } from '../../../../components/Organisms/Onboarding/SignInForm/SignInForm';

// Models
import { SignInRequest } from '../../../../services/modules/Auth/Auth.model';

/**
 * Sign In View
 * @returns
 */
export const SignInView: FunctionComponent = () => {
  const [failedLogin, setFailedLogin] = useState(false);
  const navigate = useNavigate();

  const handleSignIn = async (data: SignInRequest) => {
    setFailedLogin(false);
    httpClient.auth
      .signIn(data)
      .then((response) => {
        sessionStorage.setItem('authToken', `${response.token}`);
        navigate('/');
      })
      .catch(() => {
        setFailedLogin(true);
      });
  };
  return (
    <>
      <div className="w-full mt-4">
        <SignInForm onSubmit={handleSignIn} failedLogin={failedLogin} />
      </div>
    </>
  );
};
