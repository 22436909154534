/* Dependencies */
import * as yup from 'yup';

// Models
import {
  Floor,
  UpdateFloorReducer,
} from '../../../../store/slices/Forms/Forms.model';

export interface UpdateFloorFormProps {
  /**
   * Floor to be referenced.
   */
  data: Floor;
  /**
   * Callback function to handle form submission.
   */
  onSubmit: (data: UpdateFloorReducer) => void;
}

export const UpdateFloorFormSchema = yup.object({
  floorName: yup.string().required('Field is required'),
});
