/* Dependencies */
import { FunctionComponent } from 'react';
import { Formik } from 'formik';
import { v4 as uuid } from 'uuid';

// Services
import { httpClient } from '../../../../services/httpClient';

// Helpers
import { handleFieldError } from '../../../../helpers/handleError/handleFieldError';

// Components
import { Button } from '../../../Atoms/Button/Button';
import { FormErrorMessage } from '../../../Molecules/Forms/FormErrorMessage/FormErrorMessage';
import { Input } from '../../../Molecules/Forms/Input/Input';
import { TextArea } from '../../../Molecules/Forms/TextArea/TextArea';
import { Select } from '../../../Molecules/Forms/Select/Select';
import { FilePicker } from '../../../Molecules/Forms/FilePicker/FilePicker';
import { What3WordsInput } from '../../../Molecules/Forms/What3WordsInput/What3WordsInput';

// Models
import {
  UpdatePeopleMonitorFormProps,
  UpdatePeopleMonitorFormSchema,
  InitialValues,
} from './UpdatePeopleMonitorForm.model';
import { peopleMontitorTypes } from '../CreatePeopleMonitorForm/CreatePeopleMonitorForm.model';

/**
 * Update People Monitor Form
 * @param props - Required component props.
 */
export const UpdatePeopleMonitorForm: FunctionComponent<
  UpdatePeopleMonitorFormProps
> = ({ siteId, floorId, spaceId, data, onSubmit }) => {
  const initialValues: InitialValues = {
    name: data.name,
    location: data.location,
    serialNumber: data.serialNumber,
    deviceId: data.deviceId,
    deviceType: data.deviceType,
    photo: null,
    comment: data.comment,
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={UpdatePeopleMonitorFormSchema}
      onSubmit={async (values) => {
        let imageId = uuid();

        // Upload Photo
        await httpClient.forms.installFormAttachment({
          surveyId: siteId,
          imageId: imageId,
          image: values.photo as File,
        });

        onSubmit({
          floorId,
          spaceId,
          peopleMonitorId: data.peopleMonitorId,
          peopleMonitorIndex: data.peopleMonitorIndex,
          name: values.name,
          location: values.location,
          photoId: imageId,
          serialNumber: values.serialNumber,
          deviceId: values.deviceId,
          deviceType: values.deviceType,
          comment: values.comment,
          archived: false,
        });
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => {
        return (
          <>
            <FormErrorMessage
              propMatch={{
                name: 'Name',
                location: 'Location',
                deviceId: 'Device ID',
                deviceType: 'Device Type',
                serialNumber: 'Serial Number',
                photo: 'Photo',
                comment: 'Comment',
              }}
            />

            <form
              className="w-full flex flex-row flex-wrap items-end"
              onSubmit={handleSubmit}
            >
              {/* Name */}
              <div className="w-full">
                <Input
                  label="Name"
                  name={`name`}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.name}
                  type="text"
                  inputMode="text"
                  required={true}
                  readOnly={true}
                  error={handleFieldError(errors, touched, `name`)}
                />
              </div>
              {/* / Name */}

              {/* Markup Note */}
              <div className="w-full bg-indigo-700 text-white px-4 py-2 my-2 rounded">
                <p className="text-sm">
                  Markup On Floor Plan: P{data.peopleMonitorIndex}
                </p>
              </div>
              {/* / Markup Note */}

              {/* Location */}
              <div className="w-full mt-2">
                <What3WordsInput
                  label="Location"
                  name={`location`}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.location}
                  required={true}
                  error={handleFieldError(errors, touched, `location`)}
                />
              </div>
              {/* / Location */}

              {/* Device ID */}
              <div className="w-full mt-4">
                <Input
                  label="Device ID"
                  name={`deviceId`}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.deviceId}
                  type="text"
                  inputMode="text"
                  required={true}
                  error={handleFieldError(errors, touched, `deviceId`)}
                />
              </div>
              {/* / Device ID */}

              {/* Device Type */}
              <div className="w-full mt-2">
                <Select
                  label="Device Type"
                  name={`deviceType`}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.deviceType}
                  required={true}
                  error={handleFieldError(errors, touched, `deviceType`)}
                  options={peopleMontitorTypes}
                />
              </div>
              {/* / Device Type */}

              {/* Serial Number */}
              <div className="w-full mt-4">
                <Input
                  label="Serial Number"
                  name={`serialNumber`}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.serialNumber}
                  type="text"
                  inputMode="text"
                  required={true}
                  error={handleFieldError(errors, touched, `serialNumber`)}
                />
              </div>
              {/* / Serial Number */}

              {/* Photo */}
              <div className="w-full mt-4">
                <FilePicker
                  label="Change Photo"
                  name={`photo`}
                  onChange={handleChange}
                  value={values.photo as File}
                  required={true}
                  error={handleFieldError(errors, touched, `photo`)}
                  supportText="Upload photo of back of device displaying device ID and serial number."
                />
              </div>
              {/* / Photo */}

              {/* Comment */}
              <div className="w-full mt-4">
                <TextArea
                  label="Comment"
                  name={`comment`}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.comment}
                  error={handleFieldError(errors, touched, `comment`)}
                />
              </div>
              {/* / Comment */}

              {/* Submit */}
              <div className="w-full flex flex-row flex-wrap justify-end mt-2">
                <Button
                  title="Update"
                  ariaLabel="Update People Monitor"
                  role="submit"
                  type="primary"
                  size="medium"
                  onClick={handleSubmit}
                />
              </div>
              {/* Submit */}
            </form>
          </>
        );
      }}
    </Formik>
  );
};
