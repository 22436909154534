/* Dependencies */
import { FunctionComponent, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

// Redux
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../store/hooks/hooks';
import {
  createAirMonitor,
  createPeopleMonitor,
  createSurfaceTest,
  incrementAirMonitorsIndex,
  incrementPeopleMonitorsIndex,
  incrementSurfaceTestsIndex,
  removeAirMonitor,
  removePeopleMonitor,
  removeSpace,
  removeSurfaceTest,
  updateAirMonitor,
  updatePeopleMonitor,
  updateSpace,
  updateSpaceWorkflows,
  updateSurfaceTest,
} from '../../../../../store/slices/Forms/Forms.slice';

// Components
import { Modal } from '../../../../../components/Molecules/Modal/Modal';
import { Placeholder } from '../../../../../components/Molecules/Placeholder/Placeholder';
import { Button } from '../../../../../components/Atoms/Button/Button';
import { UpdateSpaceForm } from '../../../../../components/Organisms/Forms/UpdateSpaceForm/UpdateSpaceForm';
import { CreateAirMonitorForm } from '../../../../../components/Organisms/Forms/CreateAirMonitorForm/CreateAirMonitorForm';
import { UpdateAirMonitorForm } from '../../../../../components/Organisms/Forms/UpdateAirMonitorForm/UpdateAirMonitorForm';
import { CreateSurfaceTestForm } from '../../../../../components/Organisms/Forms/CreateSurfaceTestForm/CreateSurfaceTestForm';
import { AirMonitorsTable } from '../../../../../components/Organisms/Tables/AirMonitorsTable/AirMonitorsTable';
import { SurfaceTestsTable } from '../../../../../components/Organisms/Tables/SurfaceTestsTable/SurfaceTestsTable';
import { UpdateSurfaceTestForm } from '../../../../../components/Organisms/Forms/UpdateSurfaceTestForm/UpdateSurfaceTestForm';
import { IntroTextWithButton } from '../../../../../components/Organisms/IntroTextWithButton/IntroTextWithButton';
import { UpdateSpaceWorkflowsForm } from '../../../../../components/Organisms/Forms/UpdateSpaceWorkflowsForm/UpdateSpaceWorkflowsForm';
import { CreatePeopleMonitorForm } from '../../../../../components/Organisms/Forms/CreatePeopleMonitorForm/CreatePeopleMonitorForm';
import { UpdatePeopleMonitorForm } from '../../../../../components/Organisms/Forms/UpdatePeopleMonitorForm/UpdatePeopleMonitorForm';
import { PeopleMonitorsTable } from '../../../../../components/Organisms/Tables/PeopleMonitorsTable/PeopleMonitorsTable';

// Models
import { SpaceViewProps } from './SpaceView.model';
import {
  AirMonitor,
  PeopleMonitor,
  Space,
  SurfaceTest,
} from '../../../../../store/slices/Forms/Forms.model';

/**
 * Space View
 * @param props - Required component props.
 * @returns
 */
export const SpaceView: FunctionComponent<SpaceViewProps> = ({ floorId }) => {
  const [space, setSpace] = useState<Space>();
  const [showUpdateSpace, setShowUpdateSpace] = useState(false);
  const [showUpdateSpaceWorkflows, setShowUpdateSpaceWorkflows] =
    useState(false);
  const [showDeleteSpace, setShowDeleteSpace] = useState(false);
  const [showCreateAirMonitor, setShowCreateAirMonitor] = useState(false);
  const [airMonitorToEdit, setAirMonitorToEdit] = useState<AirMonitor>();
  const [airMonitorToDelete, setAirMonitorToDelete] = useState<AirMonitor>();
  const [showCreateSurfaceTest, setShowCreateSurfaceTest] = useState(false);
  const [surfaceTestToEdit, setSurfaceTestToEdit] = useState<SurfaceTest>();
  const [surfaceTestToDelete, setSurfaceTestToDelete] = useState<SurfaceTest>();
  const [showCreatePeopleMonitor, setShowCreatePeopleMonitor] = useState(false);
  const [peopleMonitorToEdit, setPeopleMonitorToEdit] =
    useState<PeopleMonitor>();
  const [peopleMonitorToDelete, setPeopleMonitorToDelete] =
    useState<PeopleMonitor>();

  const installSurvey = useAppSelector((state) => state.forms.installSurvey);
  const airMonitorIndex = useAppSelector(
    (state) => state.forms.airMonitorIndex
  );
  const peopleMonitorIndex = useAppSelector(
    (state) => state.forms.peopleMonitorIndex
  );
  const surfaceTestIndex = useAppSelector(
    (state) => state.forms.surfaceTestIndex
  );
  const { spaceId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const locateFloor = installSurvey.floors.find(
      (floorEntry) => floorEntry.floorId === floorId
    );

    if (!locateFloor) {
      return navigate('/forms/install-survey');
    }

    const locateSpace = locateFloor.spaces.find(
      (spaceEntry) => spaceEntry.spaceId === spaceId
    );

    if (!locateSpace) {
      return navigate('/forms/install-survey');
    }

    setSpace(locateSpace);
  }, [floorId, spaceId, installSurvey, navigate]);

  if (!space) {
    return (
      <div className="w-full">
        <p>loading</p>
      </div>
    );
  }

  return (
    <>
      <div className="w-full mb-6">
        <Button
          title="Back To Spaces"
          ariaLabel="Back To Spaces"
          type="secondary"
          onClick={() => navigate(`/forms/install-survey/floor/${floorId}`)}
        />
      </div>

      <div className="w-full my-4 flex flex-row">
        <Button
          title="Update Space"
          ariaLabel="Update Space"
          icon="edit"
          iconOnly={true}
          type="secondary"
          size="small"
          onClick={() => setShowUpdateSpace(true)}
        />
        <h2 className="text-xl ml-2">{space.spaceName}</h2>
      </div>

      <div className="w-full mt-4">
        {/* Air Monitors */}
        <div className="w-full mt-2">
          <IntroTextWithButton
            title="Workflows"
            button={{
              title: 'Add Air Monitor',
              ariaLabel: 'Add Air Monitor',
              type: 'secondary',
              icon: 'edit',
              iconOnly: true,
              size: 'medium',
              onClick: () => {
                setShowUpdateSpaceWorkflows(true);
              },
            }}
          />
        </div>

        {/* Work Spaces List */}
        <div className="w-full mt-4">
          <ul className="grid grid-cols-1 gap-2">
            {space.workflows.map((workflow, workflowIndex) => (
              <li
                key={workflowIndex}
                className="flex flex-row flex-wrap items-start relative pl-4"
              >
                <div className="w-2 h-2 bg-indigo-600 rounded-full absolute top-1/2 -translate-y-1/2 left-0"></div>
                {workflow}
              </li>
            ))}
          </ul>
          {space.comment && (
            <div className="w-full mt-4">
              <p className="text-base">
                <strong>Comment:</strong> {space.comment}
              </p>
            </div>
          )}
        </div>
        {/* / Work Spaces List */}

        {/* Work Spaces */}
      </div>

      <hr className="my-8" />

      <div className="w-full mt-4">
        {/* Air Monitors */}
        <div className="w-full mt-2">
          <IntroTextWithButton
            title="Air Monitors"
            description="Add Air Monitors to this Space"
            button={{
              title: 'Add Air Monitor',
              ariaLabel: 'Add Air Monitor',
              type: 'primary',
              icon: 'monitor',
              size: 'medium',
              onClick: () => {
                setShowCreateAirMonitor(true);
              },
            }}
          />

          <div className="w-full mt-4">
            {/* Monitors Table */}
            {space.airMonitors.length > 0 && (
              <AirMonitorsTable
                airMonitors={space.airMonitors}
                onDelete={(airMonitor) => {
                  setAirMonitorToDelete(airMonitor);
                }}
                onEdit={(airMonitor) => {
                  setAirMonitorToEdit(airMonitor);
                }}
              />
            )}
            {/* / Monitors Table */}

            {/* Placeholder */}
            {!space.airMonitors.length && (
              <Placeholder
                icon="monitor"
                title="Air Monitor"
                description="Add an Air Monitor to this Space"
              />
            )}
            {/* / Placeholder */}
          </div>
        </div>
        {/* / Air Monitors */}

        <hr className="my-8" />

        {/* Surface Tests */}
        <div className="w-full mt-2">
          <IntroTextWithButton
            title="Surface Tests"
            description="Add Surface Tests to this Space"
            button={{
              title: 'Add Surface Test',
              ariaLabel: 'Add Surface Test',
              type: 'primary',
              icon: 'surface',
              size: 'medium',
              onClick: () => {
                setShowCreateSurfaceTest(true);
              },
            }}
          />
          <div className="w-full mt-4">
            {space.surfaceTests.length > 0 && (
              <SurfaceTestsTable
                surfaceTests={space.surfaceTests}
                onDelete={(surfaceTest) => {
                  setSurfaceTestToDelete(surfaceTest);
                }}
                onEdit={(surfaceTest) => {
                  setSurfaceTestToEdit(surfaceTest);
                }}
              />
            )}

            {/* Placeholder */}
            {!space.surfaceTests.length && (
              <Placeholder
                icon="surface"
                title="Surface Test"
                description="Add a Surface Test to this Space"
              />
            )}
            {/* / Placeholder */}
          </div>
        </div>
        {/* / Surface Tests */}

        <hr className="my-8" />

        {/* People */}
        <div className="w-full mt-2">
          <IntroTextWithButton
            title="People Monitors"
            description="Add People Monitors to this Space"
            button={{
              title: 'Add People Monitor',
              ariaLabel: 'Add People Monitor',
              type: 'primary',
              icon: 'user',
              size: 'medium',
              onClick: () => {
                setShowCreatePeopleMonitor(true);
              },
            }}
          />
          <div className="w-full mt-4">
            <div className="w-full mt-4">
              {space.peopleMonitors.length > 0 && (
                <PeopleMonitorsTable
                  peopleMonitors={space.peopleMonitors}
                  onDelete={(peopleMonitor) => {
                    setPeopleMonitorToDelete(peopleMonitor);
                  }}
                  onEdit={(peopleMonitor) => {
                    setPeopleMonitorToEdit(peopleMonitor);
                  }}
                />
              )}

              {/* Placeholder */}
              {!space.peopleMonitors.length && (
                <Placeholder
                  icon="user"
                  title="People Minitors"
                  description="Add a People Monitor to this Space"
                />
              )}
              {/* / Placeholder */}
            </div>
          </div>
        </div>
        {/* / People */}

        <hr className="my-6" />

        <div className="w-full mt-6">
          <Button
            title="Delete Space"
            ariaLabel="Delete Space"
            icon="delete"
            type="danger"
            size="medium"
            onClick={() => setShowDeleteSpace(true)}
          />
        </div>

        {/* Update Space */}
        <Modal
          title="Update Space"
          isOpen={showUpdateSpace}
          onClose={() => setShowUpdateSpace(false)}
        >
          <UpdateSpaceForm
            data={space}
            floorId={floorId}
            onSubmit={(updatedSpace) => {
              dispatch(updateSpace(updatedSpace));
              setShowUpdateSpace(false);
            }}
          />
        </Modal>
        {/* / Update Space */}

        {/* Update Space Workflows */}
        <Modal
          title="Update Space Workflows"
          isOpen={showUpdateSpaceWorkflows}
          onClose={() => setShowUpdateSpaceWorkflows(false)}
        >
          <UpdateSpaceWorkflowsForm
            data={space}
            floorId={floorId}
            onSubmit={(updatedSpace) => {
              dispatch(updateSpaceWorkflows(updatedSpace));
              setShowUpdateSpaceWorkflows(false);
            }}
          />
        </Modal>
        {/* / Update Space Workflows */}

        {/* Create Air Monitor */}
        <Modal
          title="Create Air Monitor"
          isOpen={showCreateAirMonitor}
          onClose={() => setShowCreateAirMonitor(false)}
        >
          <CreateAirMonitorForm
            siteId={installSurvey.siteId}
            floorId={floorId}
            spaceId={space.spaceId}
            index={airMonitorIndex + 1}
            onSubmit={(airMonior) => {
              dispatch(createAirMonitor(airMonior));
              dispatch(incrementAirMonitorsIndex());
              setShowCreateAirMonitor(false);
            }}
          />
        </Modal>
        {/* / Create Air Monitor */}

        {/* Update Air Monitor */}
        <Modal
          title="Update Air Monitor"
          isOpen={!!airMonitorToEdit}
          onClose={() => setAirMonitorToEdit(undefined)}
        >
          {airMonitorToEdit && (
            <UpdateAirMonitorForm
              siteId={installSurvey.siteId}
              floorId={floorId}
              spaceId={space.spaceId}
              data={airMonitorToEdit as AirMonitor}
              onSubmit={(airMonior) => {
                dispatch(updateAirMonitor(airMonior));
                setAirMonitorToEdit(undefined);
              }}
            />
          )}
        </Modal>
        {/* / Update Air Monitor */}

        {/* Delete Air Monitor */}
        <Modal
          title="Archive Air Monitor"
          description="Are you sure you want to archive this air monitor?"
          isOpen={!!airMonitorToDelete}
          onClose={() => setAirMonitorToDelete(undefined)}
        >
          {airMonitorToDelete && (
            <>
              <div className="w-full bg-indigo-700 rounded p-4 text-white">
                <p className="text-base">{airMonitorToDelete.name}</p>
              </div>
              <div className="w-full mt-4">
                <Button
                  title="Archive"
                  ariaLabel="Archive"
                  type="danger"
                  onClick={() => {
                    dispatch(removeAirMonitor(airMonitorToDelete));
                    setAirMonitorToDelete(undefined);
                  }}
                />
              </div>
            </>
          )}
        </Modal>
        {/* / Delete Air Monitor */}

        {/* Create Surface Test */}
        <Modal
          title="Create Surface Test"
          isOpen={showCreateSurfaceTest}
          onClose={() => setShowCreateSurfaceTest(false)}
        >
          <CreateSurfaceTestForm
            siteId={installSurvey.siteId}
            floorId={floorId}
            index={surfaceTestIndex + 1}
            spaceId={space.spaceId}
            onSubmit={(surfaceTest) => {
              dispatch(createSurfaceTest(surfaceTest));
              dispatch(incrementSurfaceTestsIndex());
              setShowCreateSurfaceTest(false);
            }}
          />
        </Modal>
        {/* / Create Surface Test */}

        {/* Update Surface Test */}
        <Modal
          title="Update Surface Test"
          isOpen={!!surfaceTestToEdit}
          onClose={() => setSurfaceTestToEdit(undefined)}
        >
          {surfaceTestToEdit && (
            <UpdateSurfaceTestForm
              siteId={installSurvey.siteId}
              floorId={floorId}
              spaceId={space.spaceId}
              data={surfaceTestToEdit as SurfaceTest}
              onSubmit={(surfaceTest) => {
                dispatch(updateSurfaceTest(surfaceTest));
                setSurfaceTestToEdit(undefined);
              }}
            />
          )}
        </Modal>
        {/* / Update Surface Test */}

        {/* Delete Surface Test */}
        <Modal
          title="Archive Surface Test"
          description="Are you sure you want to archive this Surface Test?"
          isOpen={!!surfaceTestToDelete}
          onClose={() => setSurfaceTestToDelete(undefined)}
        >
          {surfaceTestToDelete && (
            <>
              <div className="w-full bg-indigo-700 rounded p-4 text-white">
                <p className="text-base">{surfaceTestToDelete.name}</p>
              </div>
              <div className="w-full mt-4">
                <Button
                  title="Archive"
                  ariaLabel="Archive"
                  type="danger"
                  onClick={() => {
                    dispatch(removeSurfaceTest(surfaceTestToDelete));
                    setSurfaceTestToDelete(undefined);
                  }}
                />
              </div>
            </>
          )}
        </Modal>
        {/* / Delete Surface Test */}

        {/* Create People Monitor */}
        <Modal
          title="Create People Monitor"
          isOpen={showCreatePeopleMonitor}
          onClose={() => setShowCreatePeopleMonitor(false)}
        >
          <CreatePeopleMonitorForm
            siteId={installSurvey.siteId}
            floorId={floorId}
            spaceId={space.spaceId}
            index={peopleMonitorIndex + 1}
            onSubmit={(peopleMonitor) => {
              dispatch(createPeopleMonitor(peopleMonitor));
              dispatch(incrementPeopleMonitorsIndex());
              setShowCreatePeopleMonitor(false);
            }}
          />
        </Modal>
        {/* / Create People Monitor */}

        {/* Update People Monitor */}
        <Modal
          title="Update People Monitor"
          isOpen={!!peopleMonitorToEdit}
          onClose={() => setPeopleMonitorToEdit(undefined)}
        >
          {peopleMonitorToEdit && (
            <UpdatePeopleMonitorForm
              siteId={installSurvey.siteId}
              floorId={floorId}
              spaceId={space.spaceId}
              data={peopleMonitorToEdit as PeopleMonitor}
              onSubmit={(peopleMonitor) => {
                dispatch(updatePeopleMonitor(peopleMonitor));
                setPeopleMonitorToEdit(undefined);
              }}
            />
          )}
        </Modal>
        {/* / Update People Monitor */}

        {/* Delete People Monitor */}
        <Modal
          title="Archive People Monitor"
          description="Are you sure you want to archive this people monitor?"
          isOpen={!!peopleMonitorToDelete}
          onClose={() => setPeopleMonitorToDelete(undefined)}
        >
          {peopleMonitorToDelete && (
            <>
              <div className="w-full bg-indigo-700 rounded p-4 text-white">
                <p className="text-base">{peopleMonitorToDelete.name}</p>
              </div>
              <div className="w-full mt-4">
                <Button
                  title="Archive"
                  ariaLabel="Archive"
                  type="danger"
                  onClick={() => {
                    dispatch(removePeopleMonitor(peopleMonitorToDelete));
                    setPeopleMonitorToDelete(undefined);
                  }}
                />
              </div>
            </>
          )}
        </Modal>
        {/* / Delete People Monitor */}

        {/* Delete Floor */}
        <Modal
          title="Delete Space"
          description="Are you sure you want to delete this space? This action cannot be undone."
          isOpen={showDeleteSpace}
          onClose={() => setShowDeleteSpace(false)}
        >
          <>
            <div className="w-full bg-indigo-700 rounded p-4 text-white">
              <p className="text-base">{space.spaceName}</p>
            </div>
            <div className="w-full mt-4">
              <Button
                title="Delete"
                ariaLabel="Delete Space"
                type="danger"
                onClick={() => {
                  dispatch(
                    removeSpace({
                      floorId: floorId,
                      spaceId: space.spaceId,
                    })
                  );
                  navigate(`/forms/install-survey/floor/${floorId}`);
                }}
              />
            </div>
          </>
        </Modal>
        {/* / Delete Floor */}
      </div>
    </>
  );
};
