/* Dependencies */
import { useEffect, useState } from 'react';
import {
  CogIcon,
  CubeIcon,
  DeviceMobileIcon,
  PencilIcon,
  TrashIcon,
  UserIcon,
} from '@heroicons/react/outline';

// Models
import { ButtonProps } from './Button.model';

/**
 * Button Props
 * @param props - Component props.
 */
export const Button: React.FC<ButtonProps> = ({
  onClick,
  ariaLabel,
  className,
  isDisabled,
  isLoading,
  role,
  size,
  title,
  type,
  icon,
  iconOnly,
}): JSX.Element => {
  // Define the classes to add to the button
  const [classes, setClasses] = useState<string>('');
  const [IconElement, setIconElement] = useState<JSX.Element>();
  useEffect(() => {
    let newClasses = '';
    let iconClasses = '';

    // Size
    switch (size) {
      case 'small': {
        if (iconOnly) {
          newClasses += 'px-1 py-1';
          iconClasses = ` h-4 w-4`;
        } else {
          newClasses += 'px-3 py-2 text-sm leading-4 font-medium';
          iconClasses = 'ml-2 -mr-0.5 h-4 w-4';
        }
        break;
      }
      case 'large': {
        if (iconOnly) {
          newClasses += 'px-1 py-1';
          iconClasses = ` h-5 w-5`;
        } else {
          newClasses += 'px-4 py-2 text-base font-medium';
          iconClasses = 'ml-3 -mr-1 h-5 w-5';
        }
        break;
      }
      case 'medium':
      default: {
        if (iconOnly) {
          newClasses += 'px-1 py-1';
          iconClasses = ` h-5 w-5`;
        } else {
          newClasses += 'px-4 py-2 text-sm font-medium';
          iconClasses = 'ml-2 -mr-1 h-5 w-5';
        }
        break;
      }
    }

    // Type
    switch (type) {
      case 'primary': {
        newClasses +=
          ' text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500';
        break;
      }
      case 'secondary': {
        newClasses +=
          ' text-indigo-700 bg-transparent hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 border-indigo-700 border-2 hover:text-white transition-colors';
        break;
      }
      case 'danger': {
        newClasses +=
          ' text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500';
        break;
      }

      case 'white': {
        newClasses +=
          ' text-white bg-transparent hover:bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white border-2 border-white hover:text-indigo-700 transition-colors';
        break;
      }
    }

    // Icon
    switch (icon) {
      case 'space': {
        setIconElement(<CogIcon className={iconClasses} aria-hidden={true} />);
        break;
      }
      case 'monitor': {
        setIconElement(
          <DeviceMobileIcon className={iconClasses} aria-hidden={true} />
        );
        break;
      }
      case 'surface': {
        setIconElement(<CubeIcon className={iconClasses} aria-hidden={true} />);
        break;
      }
      case 'edit': {
        setIconElement(
          <PencilIcon className={iconClasses} aria-hidden={true} />
        );
        break;
      }
      case 'delete': {
        setIconElement(
          <TrashIcon className={iconClasses} aria-hidden={true} />
        );
        break;
      }

      case 'user': {
        setIconElement(<UserIcon className={iconClasses} aria-hidden={true} />);
        break;
      }
    }

    // Set the classes
    setClasses(`${newClasses} ${className}`);
  }, [size, type, className, icon, iconOnly]);

  return (
    <button
      className={`inline-flex items-center shadow-sm rounded-md ${classes} relative`}
      aria-label={ariaLabel}
      onClick={onClick ? onClick.bind(this) : null}
      disabled={isDisabled}
      type={role ? role : 'button'}
      title={title}
    >
      {isLoading && (
        <svg
          className="w-5 h-5 mr-3 -ml-1 animate-spin"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            stroke-width="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      )}

      {!iconOnly && <span>{title}</span>}

      {icon && IconElement}
    </button>
  );
};
