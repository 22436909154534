/* Dependencies */
import * as yup from 'yup';

// Models
import { KeyValue } from '../../../../models/KeyValue';
import { CreateSurfaceTestReducer } from '../../../../store/slices/Forms/Forms.model';

export const surfaceTestDevices: KeyValue[] = [
  { key: 'Hygiena ATP', value: 'Hygiena ATP' },
  { key: 'Kikkoman ATP', value: 'Kikkoman ATP' },
];
export type SurfaceTestDevice = 'Hygiena ATP' | 'Kikkoman ATP';

export interface CreateSurfaceTestFormProps {
  /**
   * Site Id
   */
  siteId: string;
  /**
   * Current floor id.
   */
  floorId: string;
  /**
   * Current space id.
   */
  spaceId: string;
  /**
   * Index
   */
  index: number;
  /**
   * Callback function to handle form submission.
   */
  onSubmit: (data: CreateSurfaceTestReducer) => void;
}

export interface InitialValues {
  /**
   * Name of the surface test.
   */
  name: string;
  /**
   * Location of the surface test.
   */
  location: string;
  /**
   * Device type
   */
  deviceType: string;
  /**
   * Photo of the surface test.
   */
  photo: File | null;
  /**
   * Comment of the surface test.
   */
  comment: string;
  /**
   * Test result.
   */
  testResult: string;
}

export const CreateSurfaceTestFormSchema = yup.object({
  name: yup.string().required('Field is required'),
  location: yup.string().required('Field is required'),
  photo: yup.mixed().required('Field is required'),
  comment: yup.string().optional(),
  testResult: yup.string().required('Field is required'),
});
